import React, { useState, useEffect } from "react";
import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import Cookies from "universal-cookie";
import { axiosRequest } from "../../../api";
import { baseURL } from "../../../constant";

const Step3 = (props) => {
  const cookies = new Cookies();
  const [isDatePickerDisabled, setDatePickerDisabled] = useState(true);
  const deliverydate = cookies.get("deliverydate");
  const wordlength = cookies.get("wordlength");
  const [currencyList, setCurrencyList] = useState([]);
  const [languageList, setLanguageList] = useState([]);

  const getDelDate = () => {
    if (deliverydate === undefined) {
      return new Date();
    } else {
      return new Date(deliverydate);
    }
  };
  const [value, onChange] = useState(getDelDate);
  cookies.set("deliverydate", value, { path: "/" });
  const handleAnchorClick = () => {
    setDatePickerDisabled(false);
  };


  useEffect(() => {
    const getCurrency = async () => {
      const response = await axiosRequest.get(`${baseURL}/getCurrencyList`);
      setCurrencyList(response.data);
    };
    const getLanguage = async () => {
      const response = await axiosRequest.get(`${baseURL}/getLanguageList`);
      setLanguageList(response.data);
    };

    getCurrency();
    getLanguage();
  }, []);

  const marginTop33={
    marginTop:'33px',
  }
  if (props.currentStep !== 3) {
    return null;
  }
  return (
    <>
      <section className="pt-2 pb-2">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-12 col-12">
              <div className="form-group">
                <label>Word Count</label>
                <input
                  type="text"
                  className="form-control"
                  name="wordlength"
                  value={wordlength}
                  onChange={props.handleChange}
                />
              </div>
            </div>
            <div className="col-md-6 col-sm-12 col-12">
              <div className="form-group">
                <label className="w-100">
                  Deadline{" "}
                  <span className="float-right change-date small">
                    <a href="javascript:void(0);" onClick={handleAnchorClick}>
                      Change Date
                    </a>
                  </span>
                </label>
                <DateTimePicker
                  className="form-control"
                  onChange={onChange}
                  value={value}
                  disabled={isDatePickerDisabled}
                />
              </div>
            </div>
            <div className="col-md-6 col-sm-12 col-12">
              <div className="form-group">
                <label>English Type</label>
                

                <select
                  id="language"
                  className="form-control"
                  name="language"
                  onChange={props.handleChange}

                  defaultValue={{ value: 1 }}
                  >
                    {languageList?.map((item, i) => (
                    <option value={item?.id}>
                    {item?.name}
                    </option>
                    ))}

                  </select>

              </div>
            </div>
            <div className="col-md-6 col-sm-12 col-12">
              <div className="form-group">
                <label>Preferred Currency</label>
               
                <select
                  id="currency"
                  className="form-control"
                  name="currency"
                  defaultValue={cookies.get('rcurrency')}

                  onChange={props.handleChange}
                  >
                    {currencyList?.map((item, i) => (
                    <option id={item?.id} disabled={item?.id === "4"} value={item?.id}>
                    {item?.publicName}
                    </option>
                    ))}

                  </select>
              </div>
            </div>

            <div className="col-12">
              <div className="form-group">
                <label>Notes for your editor</label>
                <textarea rows="4" cols="50" className="form-control" 
                name="followUpDescription"
                value={cookies.get('followUpDescription')}
                onChange={props.handleChange}
                
                >
                  {" "}
                </textarea>
              </div>
            </div>

            <div className="col-md-6 col-sm-12 col-12">
              <div className="form-group">
                <label>Voucher Code</label>
                <input type="text" className="form-control"         
                name="voucher" 
                 onChange={props.handleChange}
                />
              </div>
            </div>
            <div className="col-md-2 col-sm-12 col-12">
                <button disabled={!cookies.get('voucher')} style={marginTop33} onClick={props.handleApplyVoucher} className="btn btn-info">Apply</button>
                </div>
          </div>
          {props.message && <div id="payment-message" className='error'>{props.message}</div>}

          
        </div>
      </section>
    </>
  );
};

export default Step3;
