import React from "react";
import { Link } from "react-router-dom";
import InnerInstantQuote from "../common/InnerinstantQuote";

function BlogPage(){
    return(
        <>
        <InnerInstantQuote />

         {/* blog section display here */}
         <section className="section-padding blog-section">
             <div className="container">
               
             <div className="row">
                    <div className="col-12 text-center mb-5">
                          <div className="section_title"><h3>Our Blog</h3></div>
                    </div>
                </div>    

              <div className="row">
                <div className="col-md-4 col-sm-12 col-12 mb-4">
                    <div className="blogbox">
                        <div className="img-box"><img src={process.env.PUBLIC_URL + '/assets/images/post-01.jpg'} className="img-fluid w-100" alt=""/></div>
                        <div className="content">
                             <h4><Link to="/blogdetail">Power of a simple illustration in brand story</Link></h4>
                             <div className="short-description">Podcasting operational change management inside of workflows to establish...</div>
                             <Link to="/blogdetail" className="custom_link">Read More</Link>
                        </div>
                    </div>
                </div>

                <div className="col-md-4 col-sm-12 col-12 mb-4">
                    <div className="blogbox">
                        <div className="img-box"><img src={process.env.PUBLIC_URL + '/assets/images/post-02.jpg'} className="img-fluid w-100" alt=""/></div>
                        <div className="content">
                        <h4><Link to="/blogdetail">Power of a simple illustration in brand story</Link></h4>
                             <div className="short-description">Podcasting operational change management inside of workflows to establish...</div>
                             <Link to="/blogdetail" className="custom_link">Read More</Link>
                        </div>
                    </div>
                </div>

                <div className="col-md-4 col-sm-12 col-12 mb-4">
                    <div className="blogbox">
                        <div className="img-box"><img src={process.env.PUBLIC_URL + '/assets/images/post-01.jpg'} className="img-fluid w-100" alt=""/></div>
                        <div className="content">
                        <h4><Link to="/blogdetail">Power of a simple illustration in brand story</Link></h4>
                             <div className="short-description">Podcasting operational change management inside of workflows to establish...</div>
                             <Link to="/blogdetail" className="custom_link">Read More</Link>
                        </div>
                    </div>
                </div>       

                <div className="col-md-4 col-sm-12 col-12 mb-4">
                    <div className="blogbox">
                        <div className="img-box"><img src={process.env.PUBLIC_URL + '/assets/images/post-01.jpg'} className="img-fluid w-100" alt=""/></div>
                        <div className="content">
                             <h4><Link to="/blogdetail">Power of a simple illustration in brand story</Link></h4>
                             <div className="short-description">Podcasting operational change management inside of workflows to establish...</div>
                             <Link to="/blogdetail" className="custom_link">Read More</Link>
                        </div>
                    </div>
                </div>

                <div className="col-md-4 col-sm-12 col-12 mb-4">
                    <div className="blogbox">
                        <div className="img-box"><img src={process.env.PUBLIC_URL + '/assets/images/post-02.jpg'} className="img-fluid w-100" alt=""/></div>
                        <div className="content">
                        <h4><Link to="/blogdetail">Power of a simple illustration in brand story</Link></h4>
                             <div className="short-description">Podcasting operational change management inside of workflows to establish...</div>
                             <Link to="/blogdetail" className="custom_link">Read More</Link>
                        </div>
                    </div>
                </div>

                <div className="col-md-4 col-sm-12 col-12 mb-4">
                    <div className="blogbox">
                        <div className="img-box"><img src={process.env.PUBLIC_URL + '/assets/images/post-01.jpg'} className="img-fluid w-100" alt=""/></div>
                        <div className="content">
                        <h4><Link to="/blogdetail">Power of a simple illustration in brand story</Link></h4>
                             <div className="short-description">Podcasting operational change management inside of workflows to establish...</div>
                             <Link to="/blogdetail" className="custom_link">Read More</Link>
                        </div>
                    </div>
                </div>   


                <div className="col-md-4 col-sm-12 col-12 mb-4">
                    <div className="blogbox">
                        <div className="img-box"><img src={process.env.PUBLIC_URL + '/assets/images/post-01.jpg'} className="img-fluid w-100" alt=""/></div>
                        <div className="content">
                             <h4><Link to="/blogdetail">Power of a simple illustration in brand story</Link></h4>
                             <div className="short-description">Podcasting operational change management inside of workflows to establish...</div>
                             <Link to="/blogdetail" className="custom_link">Read More</Link>
                        </div>
                    </div>
                </div>

                <div className="col-md-4 col-sm-12 col-12 mb-4">
                    <div className="blogbox">
                        <div className="img-box"><img src={process.env.PUBLIC_URL + '/assets/images/post-02.jpg'} className="img-fluid w-100" alt=""/></div>
                        <div className="content">
                        <h4><Link to="/blogdetail">Power of a simple illustration in brand story</Link></h4>
                             <div className="short-description">Podcasting operational change management inside of workflows to establish...</div>
                             <Link to="/blogdetail" className="custom_link">Read More</Link>
                        </div>
                    </div>
                </div>

                <div className="col-md-4 col-sm-12 col-12 mb-4">
                    <div className="blogbox">
                        <div className="img-box"><img src={process.env.PUBLIC_URL + '/assets/images/post-01.jpg'} className="img-fluid w-100" alt=""/></div>
                        <div className="content">
                        <h4><Link to="/blogdetail">Power of a simple illustration in brand story</Link></h4>
                             <div className="short-description">Podcasting operational change management inside of workflows to establish...</div>
                             <Link to="/blogdetail" className="custom_link">Read More</Link>
                        </div>
                    </div>
                </div>   


                <div className="col-md-4 col-sm-12 col-12 mb-4">
                    <div className="blogbox">
                        <div className="img-box"><img src={process.env.PUBLIC_URL + '/assets/images/post-01.jpg'} className="img-fluid w-100" alt=""/></div>
                        <div className="content">
                             <h4><Link to="/blogdetail">Power of a simple illustration in brand story</Link></h4>
                             <div className="short-description">Podcasting operational change management inside of workflows to establish...</div>
                             <Link to="/blogdetail" className="custom_link">Read More</Link>
                        </div>
                    </div>
                </div>

                <div className="col-md-4 col-sm-12 col-12 mb-4">
                    <div className="blogbox">
                        <div className="img-box"><img src={process.env.PUBLIC_URL + '/assets/images/post-02.jpg'} className="img-fluid w-100" alt=""/></div>
                        <div className="content">
                        <h4><Link to="/blogdetail">Power of a simple illustration in brand story</Link></h4>
                             <div className="short-description">Podcasting operational change management inside of workflows to establish...</div>
                             <Link to="/blogdetail" className="custom_link">Read More</Link>
                        </div>
                    </div>
                </div>

                <div className="col-md-4 col-sm-12 col-12 mb-4">
                    <div className="blogbox">
                        <div className="img-box"><img src={process.env.PUBLIC_URL + '/assets/images/post-01.jpg'} className="img-fluid w-100" alt=""/></div>
                        <div className="content">
                        <h4><Link to="/blogdetail">Power of a simple illustration in brand story</Link></h4>
                             <div className="short-description">Podcasting operational change management inside of workflows to establish...</div>
                             <Link to="/blogdetail" className="custom_link">Read More</Link>
                        </div>
                    </div>
                </div>            

              </div>

              

             </div>
         </section>
         {/* blog section display end here */}   
             
        </>
    );
}
export default BlogPage;