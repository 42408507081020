import React from "react";

function PrivacyPolicy(){
    return(
        <>
          <section className="inner-page section-themebg">
              <div className="container">
                <div className="row">
                    <div className="col-12 text-center">
                        <h3>Privacy Policy</h3>
                    </div>
                </div>
              </div>
         </section>

         <section className="section-padding inner-content">
              <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-9  col-sm-12 col-12 ">
                        <h3>Privacy Policy</h3>
                        <p>This is the Privacy Policy of WordsRU Pty Ltd., trading under the brand name WordsRU. This Privacy Policy explains how we receive, use, and protect your personal information.Privacy Seals As a professional online business, WordsRU respects privileged information and understands that you have entrusted us with your intellectual property, business data, or personal writing.</p>

<p>We treat this trust with the respect it deserves - our business depends on it!</p>

<p>WordsRU is committed to protecting your privacy. We adhere to the Privacy Act 1988, the Privacy Amendment (Private Sector) Act 2000, and similar international privacy acts and provisions as they relate to all information we collect from you. The Privacy Act 1988 incorporates the National Privacy Principles, and the information held by WordsRU will be treated in accordance with those Principles. We use best industrial practices to protect the information given by you to us.</p>

<p>The WordsRU website is part of the global WordsRU business. Using the Internet to collect and process personal information necessarily involves the transmission of information across borders. Where personal information is transferred across borders, we will take reasonable steps to uphold principles for fair handling of your personal information in accordance with international privacy standards.</p>

<h4>Changes to Privacy Policy</h4>
<p>WordsRU reserves the right to change the Privacy Policy where deemed necessary. Any change to the Privacy Policy will come into effect immediately upon notification on the WordsRU website. Following such notification, your continued use of this website will constitute your acceptance of these changes. We recommend that you review this Privacy Policy from time to time.</p>
<h4>Personal Information</h4>
<p>When you visit the WordsRU website, information regarding your visit is recorded by our server or on our behalf by a third party. This information includes: the date and time of your visit, your geographical location, the pages you access, the type of operating system and browser you use, and the previous website from which you were referred. We also use a cookie to authenticate you when you log in to the website.</p>

<p>When you transact with WordsRU, information regarding your transaction is recorded and used to offer our editorial services to you. This information includes: your name, e-mail address, country, preferred currency, costs, dates of transactions, and data pertaining to the editing process.</p>

<p>WordsRU does not collect or store any credit card information relating to your payment. All payments are conducted through WorldPay and PayPal facilities, which provide highly secure credit card transactions.</p>

<p>The personal data we collect is used to manage our commercial relationship with you, to perform administrative functions and marketing analyses, to enhance our website, and to improve our services. WordsRU does not disclose any of your personal information to any third party, with the exception of third parties that we contract with to provide a service on our behalf. For this purpose, the third party only receives the data necessary for it to provide the specified service. This service may relate to communicating with you via e-mail, newsletter, or telephone.</p>

<p>By using the website and transacting with WordsRU, you agree to our collection of this data as stated in the Privacy Policy, and agree to our contacting you for administrative or marketing purposes.</p>

<h4>Accuracy and Security</h4>
<p>WordsRU maintains the accuracy of personal information, and will take action to correct any inaccuracies where identified. Your personal information is only disclosed to WordsRU employees and third parties where necessary to provide a service to us. We will take all necessary steps to protect data from misuse, loss, unauthorized access, modification, or disclosure.</p>
<h4>Destruction of Personal Information</h4>
<p>WordsRU will destroy/delete all personal information from our systems when it is no longer required by us or in accordance with applicable laws.</p>

<p>All documents are automatically and permanently deleted from our system within 120 days of job closure.</p>

<h4>Editors and Employees</h4>
<p>We appreciate the sensitivity of your documents and we take our commitment to document confidentiality and security very seriously. Documents are sent and received within a secure, purpose-designed system via our website.</p>

<p>Our editors have signed confidentiality agreements with WordsRU, and their skills and competencies have been established to our complete satisfaction. The client retains sole copyright, ownership, and control over all materials submitted to WordsRU for proofreading and editing, including any materials subsequently created or developed by WordsRU.</p>
<h4>Access to Personal Information</h4>
<p>You may at any time add or modify the personal information that you have provided to us. We provide you with information access and correction, free of charge. To obtain details or to change any of the personal information you have provided to WordsRU, please contact us.</p>
<h4>Marketing</h4>
<p>WordsRU strongly opposes spam. WordsRU adheres to the SPAM Act (2003) and similar international acts, and does not use your information to send any unsolicited emails. We use your information only to send you newsletters or marketing communications if you have completed a job with us or if you have subscribed to receive them. You can opt out of marketing emails at any time. We do not distribute any mailing lists to any third parties.</p>

<p>You can unsubscribe to marketing emails by clicking the &quot;unsubscribe&quot; link on any marketing email you receive.</p>

<p>Your details will be removed from the list within one day of WordsRU's receipt of the unsubscribe request.</p>

<h4>Cookies Policy</h4>
<p>Cookies allow us to identify your device, or your personal login information. We use cookies to provide certain basic features available to you when you visit our website. We use cookies to enhance the functionality of the website by storing your preferences, for example. We also use cookies to help us improve the performance of our website, which provides you with a better user experience.</p>

<p>Typically, we use cookies to deliver the following services throughout the pages of our website:</p>

<p>To enable us to recognize your device so you don't have to give the same information repeatedly; To recognize that you may have requested that we remember your username and password so you don't need to enter your details each time you visit the site; To ensure that if you are purchasing a service via our website, your experience is smooth and secure; To record what people like and don't like on the website and to give us an indication of how the website performs at points of high usage. WordsRU also uses a number of independent measurements, advertising and research companies. They gather information regarding the visitors to WordsRU on our behalf using cookies, log file data, and code, which is embedded on our website. WordsRU uses this type of information to help improve the services we provide to our users. You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can modify your browser settings to decline cookies, if you prefer.</p>

<p>We use Google Analytics to track demographic or anonymous data depending on your browser settings, and these analytics allow us to tailor our marketing to better suit your needs.</p>

<p>You can opt out or control your use of Google cookies here. You can opt out of a third-party vendor's use of cookies here</p>

<h4>Links to External websites</h4>
<p>From time to time, WordsRU may display links to external websites. The WordsRU Privacy Policy only applies to the practices of the WordsRU website and not to the practices or content of any linked websites.</p>

<h4>Complaints Process</h4>
<p>If you wish to register a complaint with WordsRU regarding our Privacy Policy, or any other WordsRU service, please contact WordsRU. State the exact details of the complaint and how we may contact you.</p>

<p>WordsRU will verify that we have received your complaint, and then investigate and correspond with you to resolve any problems.</p>

<p>For more information on the security of the WordsRU website, please visit our Security Information page.</p>
                    </div>
                </div>
              </div>
         </section>


        </>
    );
}
export default PrivacyPolicy;