import React from "react";

function QualityAssurance(){
    return(
        <>
          <section className="inner-page section-themebg">
              <div className="container">
                <div className="row">
                    <div className="col-12 text-center">
                        <h3>Quality Assurance</h3>
                    </div>
                </div>
              </div>
         </section>


         <section className="inner-content section-padding">
              <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-9 col-sm-12 col-12">
                          
                    <p>WordsRU&rsquo;s top-quality editing and proofreading service delivers the best possible outcomes for our customers.</p>

<p>We have served a wide variety of clients for almost ten years, including major corporations, thousands of students at all levels, top-selling authors, and hundreds of people who know what they want to say and just need a little help to say it better!</p>

<p>Our commitment to quality is based on the conviction that customers</p>

<ul>
    <li>Require top-quality editing from professional and highly-qualified editors</li>
    <li>Prefer a fast and efficient response and turnaround service</li>
    <li>Benefit from a personal, one-on-one service</li>
    <li>Appreciate editors who are dedicated to making each document the best it can be</li>
    <li>Recognize the advantage of having a chief editor monitor the process from start to finish, and</li>
    <li>Demand security, confidentiality, and privacy for themselves and their documents.</li>
</ul>

                    <h4>Deadline Guarantee</h4> 
                    <p>We understand how critical time is to our customers, and we aim to return each client&rsquo;s document prior to the agreed deadline. If we fail to meet a deadline, you may request a refund or credit of the amount paid for that job. This guarantee applies to all WordsRU customers. Upon missing a deadline we will either;</p>
<ul>
                    <li>provide a complete refund or credit for the project concerned or return your completed document as soon as possible. If you choose the former, you will need to let us know your decision before the completed edit is returned to you.</li>

<li>This delivery guarantee applies equally to our Express Service, so long as you have received a message from a chief editor that the deadline can be met. With tight turnaround times, your chief editor will let you know if the deadline might affect the thoroughness of editing.</li>
</ul>
<p>WordsRU operates a customer-focused, online editorial service that welcomes and monitors customer feedback; we continually strive to improve and enhance the quality of our service. Your editor carefully reads your document, word by word, and does not rely on automated grammar or spell checks, or any kind of editing software.</p>

<p>We are careful to select only the most experienced and qualified editors. All WordsRU editors must satisfactorily complete a rigorous proficiency test that is used to measure their particular skills and areas of expertise. Furthermore, our editors are committed to meeting WordsRU&rsquo;s principles and goals. They deliver top-quality editing and proofing of documents to the continuing satisfaction of our customers.</p>

<p>We invite you to learn more about our professional editors by reviewing their profiles online!</p>

<p>Our chief editors oversee all jobs to ensure that your edit runs smoothly and that you receive the quality service for which we are renowned. Once you submit your quote request, you will be welcomed by the chief editor who will monitor your job. The chief editor will answer any questions you have regarding pricing, our editing staff, and our services. Then, the chief editor will carefully select an appropriate editor for your job&mdash;or make every effort to assign the editor of your preference. The chief editor will remain available to you throughout the editing process. If you have concerns or questions at any point, you can either post a message on the job page or call us on the phone, and the chief editor will provide assistance. If you have any questions after the job has been completed, the chief editor will be happy to help you.</p>

<p>WordsRU is open 24 hours a day, 7 days a week to customers around the world, and considers swift, timely communication a high priority. We understand that your time is valuable, which is why we will respond to your request for a quote within at least 12 hours. Most importantly, our service is not an impersonal, automated response! At WordsRU, you will deal with real people who understand your editing needs. Your editor will be matched to your needs based on your document&rsquo;s content and type; he or she will communicate with you directly via our purpose-built, secure, document transfer system.</p>

<p>WordsRU is serious when it comes to the security, confidentiality, and privacy of you and your documents. Our editors and staff have signed confidentiality agreements with WordsRU; their qualifications and integrity have been established to our complete satisfaction. If required, WordsRU will be happy to sign any additional non-disclosure agreements that you require.</p>

<p>All documents and data transferred through our secure online system are encrypted to ensure the absolute confidentiality of your work. For more information on security, please refer to our Security Statement. WordsRU has a strict policy on privacy and treats your personal information with the utmost respect. For more information on WordsRU&rsquo;s privacy policy, please refer to our Privacy Policy.</p>
<h4>Guarantee of Quality Service</h4>
<p>WordsRU strives to provide complete satisfaction to customers through a top-quality editing service. However, we recognize that editing can be subjective and expectations for improvement may vary. WordsRU is committed to resolving all outstanding issues. If you are not completely satisfied with any aspect of our service, please contact WordsRU so we can address your concerns.</p>
                    </div>
                </div>
              </div>
         </section>

        </>
    );
}
export default QualityAssurance;