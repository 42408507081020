import React from "react";

function SecurityInfomartion(){
    return(
        <>
          <section className="inner-page section-themebg">
              <div className="container">
                <div className="row">
                    <div className="col-12 text-center">
                        <h3>Security Infomartion</h3>
                    </div>
                </div>
              </div>
         </section>

         <section className="section-padding  inner-content">
              <div className="container">
                <div className="row">
                    <div className="col-12">
                        <p>WordsRU provides security, integrity, and authenticity for all data via our secure system, and we use up-to-date technology to maintain our commitment.</p>
                    </div>
                    <div className="col-md-9 col-sm-12 col-12">
                    <p>Although we take every current security measure available, not all data transmissions over the Internet are completely secure, and WordsRU cannot absolutely guarantee the security of any data sent to us via the Internet.</p>
                    
                    <p>When you visit our Detailed Quote page to enter your details and upload a document, or when you log in to the WordsRU website, you are visiting a secure page. Our secure pages use secure socket layers (SSL) technology with 128-bit encryption, which is the industry standard for safely delivering your details and document(s) to our purpose-built system</p>

                    <p>You can identify a secure page by the "https" that forms the first part of the web address (https://www.wordsru.com) and by the small yellow padlock that displays in the bottom right corner of the browser status bar. You can verify the security certificate by double-clicking on the padlock.</p>

                    <p>As a security precaution, all documents are uploaded and downloaded via our secure website. WordsRU does not e-mail documents directly to customers.</p>

                    </div>

                    <div className="col-md-3 col-sm-12 col-12">
                         <img src={process.env.PUBLIC_URL + '/assets/images/ssl.jpg'} className="img-fluid w-100" alt="" />
                    </div>

                </div>

                <div className="row">
                    <div className="col-12">
                        <h4>Security Precautions</h4>
                        <p>WordsRU will not disclose your username and password to anyone other than the authorized owner of your WordsRU account. If you cannot remember your password, WordsRU has the functionality to issue you, as an authorized owner of an account, a new password.</p>

                        <p>We recommend that you do not disclose your username and password to any other party at any time. You can change your username and password at any time by logging in to our system and clicking on User Details.</p>

<p>WordsRU uses the latest in anti-virus protection to ensure that all our systems and documents are free of viruses and any form of adware. We strongly recommend that you install recognized anti-virus protection on your own computer to ensure that your document is virus-free.</p>

<p>If you have any questions regarding this security information, or wish to discuss any concerns you have, please contact WordsRU.</p>

<p>For more information on WordsRU's privacy policy, please refer to our Privacy Policy.</p>
                    </div>
                </div>

              </div>
         </section>

        </>
    );
}
export default SecurityInfomartion;