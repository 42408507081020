import React from "react";

function TermsConditions(){
    return(
        <>
          <section className="inner-page section-themebg">
              <div className="container">
                <div className="row">
                    <div className="col-12 text-center">
                        <h3>Terms and Conditions</h3>
                    </div>
                </div>
              </div>
         </section>

         <section className="section-padding inner-content">
               <div className="container">
                   <div className="row justify-content-center" >
                    <div className="col-md-9 col-sm-12 col-12">
                        
                        <p>Use of the WordsRU website or the contracting of WordsRU services is acknowledgement and acceptance of the company's terms and conditions as detailed on this page.</p>
                        <h3>1. Confidentiality, Ownership, and Copyright of Documents</h3>
                        <p>WordsRU is committed to providing a secure, reliable, and confidential service to our customers. We use SSL (secure socket layer) technology with a purpose-built system to ensure the security of file transfers. For more information on our security measures, please view the Security Information page.</p>

<p>All of our staff members, including our editors, have signed confidentiality agreements with WordsRU and their qualifications have been established to our complete satisfaction. For more information on our privacy policy, please view the Privacy Statement page.</p>

<p>We take the protection of intellectual copyright, classified material, and other forms of confidential documents very seriously. Our customers retain sole copyright, ownership, and control over all materials submitted to WordsRU for proofreading and editing, including any materials subsequently created or developed by WordsRU.</p>

<h4>2. Content of Submitted or Created Materials</h4>
<p>Customers are advised that plagiarism and breach of copyright are serious offenses. WordsRU reserves the right to decline requests for price quotes from any potential academic clients who submit a job that involves the reworking of plagiarized content. In situations where copyright infringement or plagiarism is discovered or suspected after a document has been accepted for editing, customers are advised of the serious nature of such breaches and editing will not proceed. For more information on academic papers, copyright, and plagiarism, please refer to our Academic Editing Policy.</p>

<p>Although we recognize the right to free speech, WordsRU reserves the right to refuse to proofread, edit, or create any document that advocates or incites violence, hatred, or discrimination, as well as any document that is obscene or pornographic, or in contravention of national and international laws.</p>

<p>Users of the WordsRU website are prohibited from posting or transmitting to the site any material that is virus infected, unlawful, threatening, libelous, defamatory, obscene, or pornographic, as well as any other material that would violate any law.</p>

<p>The information contained in customer documents edited by WordsRU may include opinions or views that are not those of WordsRU or any person associated with WordsRU. WordsRU is not responsible or liable in relation to the use of any information contained in customer documents including misleading or defamatory statements, or the infringement of third party copyright.</p>

<h4>3. Service Quality and Standards</h4>
<p>WordsRU strives to provide complete satisfaction to our customers through a top quality editing service. For more information, please view our Quality Assurance page. All documents are proofread, edited, or created to a high and exacting standard. However, we do not guarantee that edited documents are "perfect" and "error-free."</p>

<p>WordsRU is not liable for incidental, indirect, consequential, special, punitive, or exemplary damages, including lost revenues or profits, loss of business, or loss of data related to this service. This exemption applies to any claim, loss, or injury based on errors, omissions, interruptions, or any other inaccuracies that may appear on the WordsRU website or in an edited customer document.</p>

<p>WordsRU is not obligated to refund the cost of the service in the case of an edited document being less than perfect. We request that the customer contact us if not completely satisfied with the service and we will make every effort to remedy the situation.</p>

<p>The customer is responsible for reviewing the edited document upon completion and contacting WordsRU should any errors be discovered. WordsRU will correct any outstanding issues with an edited or copy written document through our complaints process, which is outlined below:</p>

<ul>
   <li>The customer must submit his or her complaint in writing on the job page within 120 days from the time of job completion.</li>
   <li>The chief editor will review all communications on the job page, and then respond to the customer in a timely fashion. A remedy will be presented by the chief editor. For example, the chief editor may direct the editor to correct the specific issues contained in the complaint. In some cases, the chief editor will personally go through the document and make necessary corrections.</li>
   <li>If the customer is still unsatisfied after the chief editor has provided a remedy, the chief editor will forward the complaint to the operations manager for final resolution.</li>
</ul>



<h4>4. Accuracy of Client Information</h4>
<p>The customer is responsible for providing true and accurate information with respect to his or her name, address, phone number, email address, and all payment details. Failure to provide correct and accurate information can result in a refusal to provide service. The customer assumes all responsibility for any problems, errors, and issues, as well as legal liability, resulting from such false information. All information submitted by the customer is confidential and secure. Please read our Privacy Statement and Security Information pages for more details.</p>

<h4>5. Ownership and Copyright of Business Names and Website Content</h4>
<p>The WordsRU website (http://www.wordsru.com), any subsidiary websites and the business name "WordsRU" are owned and operated by WordsRU. All material on this site and subsidiary sites including services, images, policies, copy, and prices are protected by international copyright and trademark law. These names and the website content cannot be used, copied, reproduced, republished, transmitted, framed, printed, or modified without the express permission of WordsRU.</p>

<p>Anyone wishing to link to the WordsRU website or advertise our services should contact us first.</p>

<h4>6. Refusal or Denial of Service</h4>
<p>WordsRU reserves the right to deny or refuse service to any customer or prospective customer for any reason and without explanation.</p>

<h4>7. Financial Fraud or Theft</h4>
<p>WordsRU is not liable for the fraudulent use of any credit card or other payment method that may be used to purchase its services.</p>

<h4>8. Indemnification</h4>
<p>The customer agrees to indemnify and hold WordsRU and anyone associated with WordsRU harmless from any claim or demand, including reasonable fees and costs for the service of an attorney, or as may be made by any third party due to or arising from use of the site or service, or violation of the Terms and Conditions by the customer, or infringement of any intellectual property or other right of any person or entity by the customer.</p>

<p>WordsRU gives no assurances or warranty regarding the accuracy, currency, or applicability of any of the website contents in relation to specific situations and particular circumstances, and disclaims all liability with respect to such information.</p>

<h4>9. Technical Disclaimer</h4>
<p>WordsRU is not liable or responsible for service delays resulting from unforeseen circumstances, such as technical problems, including server or connection issues that are beyond our control. We will make every effort to meet deadlines and communicate with customers in the case of technical problems.</p>

<p>WordsRU is not liable or responsible for any damage to computer hardware or software arising from the distribution of a computer virus through the use of the WordsRU website or system.</p>

<h4>10. Termination of Service and Contractual Obligations</h4>
<p>In the event a customer violates any or all of the Terms and Conditions contained in this document, WordsRU reserves the right to terminate all service and contractual obligations with the customer. Furthermore, WordsRU may, at its discretion, discontinue or terminate operation of its website, services, or trading name without notice at any time and for any reason.</p>

<p>A contract between WordsRU and the customer commences as soon as the customer has submitted payment for the service and the payment has been received by WordsRU. When payment is received, the customer has confirmed that the work is to commence.</p>

<p>Should the customer wish to cancel the service once work has commenced, a credit will be applied to the customer's account. Refund of money paid is at the discretion of WordsRU and may incur any associated cost incurred by WordsRU, including bank/transfer fees and administrator/editor costs.</p>

<p>The customer is responsible for providing accurate and descriptive requirements to WordsRU at the time of submitting a job, or prior to a quote being issued. These requirements form the statement of work. Failure by the customer to provide correct and accurate information in the statement of work may result in the cancellation of a transaction. Where a transaction is cancelled due to a breach of these terms and conditions, a refund will be offered to the customer of all money paid less any associated cost incurred by WordsRU, including bank/transfer fees and editor costs. The customer assumes all responsibility for any problems, errors, and issues, as well as legal liability resulting from an incorrect statement of work.</p>

<p>WordsRU reserves the right to cancel service to any customer when a virus is suspected in submitted documents. In the case of a transaction, a refund will be issued less any associated cost incurred by WordsRU, including bank/transfer fees, editor costs, and virus removal costs.</p>

<p>WordsRU reserves the right to cancel service to any customer for any reason and without explanation.</p>

<p>Account customers submitting work to WordsRU are responsible for paying the monthly invoice in full within 14 days of the date of the invoice. Failure to meet this requirement may result in a termination of services by WordsRU and subsequent action to retrieve arrears.</p>

<h4>11. Jurisdiction</h4>
<p>WordsRU is governed by the laws of the State of Victoria in Australia; by accepting these terms and conditions, you submit to the jurisdiction of the courts in that state.</p>

<h4>12. Modification of Terms and Conditions</h4>
<p>The terms and conditions contained herein may be modified, amended, altered, changed, reorganized, rewritten, or revoked at any time, for any reason, and without notice.</p>

<h4>13. 48-hour Deadline Guarantee</h4>
<p>Our 48-hour deadline guarantee is subject to the job being less than  5,000 words in total. The 48-hour guarantee delivery deadline is set from the time and date we receive written notification of payment from our payment gateway provider. If the deadline is not met, we will provide a credit or a full refund.</p>

<h4>14. Destruction of Personal Information</h4>
<p>WordsRU will destroy/delete all personal information from our systems when it is no longer required by us or in accordance with applicable laws.</p>

<p>All documents are automatically and permanently deleted from our system within 120 days of job closure.</p>

                    </div>
                   </div>

               </div>

         </section>

        </>
    );
}
export default TermsConditions;