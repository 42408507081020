import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../assets/css/myaccount.css";
import { axiosRequest } from "../../api";
import { baseURL } from "../../constant";
import Cookies from "universal-cookie";
import { useEffect } from "react";

// import Cookies from "universal-cookie";

function UserLogout() {
  const cookies = new Cookies();
  const navigate = useNavigate();
  const deleteAllCookies = () => {
    const cookies = document.cookie.split(";");

    cookies.forEach((cookie) => {
        const name = cookie.split("=")[0].trim();
        if (name !== 'setting') {
          document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        }
    });
    window.location.href = '/';
};

// Clear cookies and perform other logout tasks
deleteAllCookies();
  
  
}

export default UserLogout;
