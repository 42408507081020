import React from "react";
import { Link } from "react-router-dom";

function Jobs(){
    return(
        <>
          <section className="inner-page section-themebg">
                <div className="container">
                <div className="row">
                    <div className="col-12 text-center">
                        <h3>Jobs</h3>
                    </div>
                </div>
                </div>
            </section>

            <section className="section-padding jobs_page">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center mb-4"><h3>Jobs at WordsRU | WordsRU Careers</h3></div>
                        <div className="col-md-8 col-sm-12 col-12">
                              <h4>Applicants must meet the following requirements:</h4>
                              <ul>
                                <li>Hold a Master's degree, PhD, or equivalent professional experience in any academic discipline.</li>
                                <li>Have at least two years of professional experience as an editor, copyeditor, or proofreader.</li>
                                <li>Be proficient in MS Word and its tracked changes feature.</li>
                                <li>Have experience in efficient document management.</li>
                                <li>Be familiar with at least two of the following:
                                    <ol>
                                        <li>Academic documentation citation and formatting styles, such as APA, MLA, Chicago Manual of Style, Turabian, and Harvard style.</li>
                                        <li>Business documents, including marketing, corporate communications, speeches, multimedia presentations, training materials, user guides, e-learning, and online help.</li>
<li>Manuscripts in all genres: novels, screenplays, short stories, poetry, biographies, etc.</li>
<li>Web content: website editing and analysis in terms of effective design, 508 accessibility, navigational ease, and SEO.</li>
                                    </ol>

                                </li>

                                <li>Have additional proficiency in at least one of the following applications (optional):

                                    <ol>
                                        <li>Adobe Acrobat Vs 8 (Professional) for editing PDFs</li>
                                        <li>Adobe InDesign</li>
                                        <li>Adobe Framemaker</li>
                                        <li>DITA and Arbortext Editor</li>
                                        <li>QuarkXPress</li>
                                        <li>Final Draft</li>
                                        <li>Movie Magic Screenwriter</li>
                                        <li>LATEX</li>
                                    </ol>
                                </li>

                                <li>Have reliable access to the Internet and the ability to access the Internet several times a day; be committed to quality editing; have a responsible and flexible attitude; work well under pressure; adhere to deadlines; and provide a consistently superior level of work. Before sending your resumes / CVs / cover letters, please check the following requirements:
                                    <ol>
                                        <li>Applicants must be willing to work as independent contractors.</li>
                                        <li>Applicants must have a reliable high-speed Internet connection.</li>
                                        <li>Payment rates will only be discussed personally with successful applicants.</li>
                                    </ol>


                                </li>

                              </ul>


                              <h4 className="mt-5">Editor Openings</h4>
                              <p>We are currently seeking editors with the above requirements</p>
                              <p><b>Academically Qualified & Experienced Editors Wanted (any country)</b></p>

                              <ul>
                                <li>Academic Editors
                                    <ol>
                                        <li>ESL experience highly regarded</li>
                                        <li>APA (6th), Chicago, or Harvard style experience a must</li>
                                    </ol>
                                </li>
                                <li>Technical/Science Editors (IEEE citation style) also needed</li>
                                <li>Business Materials Editors (Web content experience a plus)</li>

                              </ul>

                              <p className="mt-4"><b>NOTE:</b> We are currently in need of qualified editors who are available to work on weekends.</p>

<p>If you meet any of these requirements, please send your CV or resume and cover letter to <Link to="mailto:jobs@wordsru.com">jobs@wordsru.com</Link>. Due to the large volume of applications we receive, please understand if we do not respond personally to your inquiry. No follow-up e-mails or phone calls, please.
                              </p>

                        </div>
                        <div className="col-md-4 col-sm-12 col-12">
                              <div className="jobform_box">
                                   <h4 className="mb-4">Apply for Job</h4>
                                  <div className="form-group mb-2">
                                       <label>Full Name</label>
                                       <input type="text" className="form-control" />
                                  </div>

                                  <div className="form-group mb-2">
                                       <label>Email Address</label>
                                       <input type="text" className="form-control" />
                                  </div>

                                  <div className="form-group mb-2">
                                       <label>Attached Resume</label>
                                       <input type="file" className="form-control" />
                                  </div>

                                  <div className="form-group mb-2">
                                       <label>Message</label>
                                       <textarea className="form-control"></textarea>
                                  </div>

                                  <div className="form-group text-right">                                        
                                       <Link to="#" className="btn btn-dark rounded-0 mt-4">Submit</Link>
                                  </div>

                              </div>
                        </div>
                    </div>
                </div>
            </section>            
          

        </>

    );
}

export default Jobs;