import React from "react";
import { Link } from "react-router-dom";

function Aboutus(){
    return(
        <>
          <section className="inner-page section-themebg">
                <div className="container">
                <div className="row">
                    <div className="col-12 text-center">
                        <h3>About us</h3>
                    </div>
                </div>
                </div>
            </section>


            <section className="section-padding">
                  <div className="container">
                  <div className="row">
                    <div className="col-md-9 col-sm-12 col-12">
                        <h2 className="team_title">About WordsRU</h2>
                        <div className="subdescription">
                            <p>We provide a specialist proofreading and editing service to businesses, academics, and writers. WordsRU focuses on providing our customers with value for their money by delivering a quality edit every time. We do not run a labor marketplace, with multiple contractors bidding for the lowest price. Instead, customer satisfaction through quality is our objective.</p>

<p>Why "WordsRU"? People are often judged on their ability to express themselves. In essence, your words are you - they represent your thoughts and are the building blocks of your personal, professional, and academic contributions. Just as good communicators invariably secure the best jobs, documents that are well written and presented with attention to style achieve greater recognition and success for their creators.</p>

<p>WordsRU is a privately-held, online editing company based in Australia (ABN 27 622 203 374). We were established in 2002 to meet the needs of business professionals, members of academia, the writing community, and individuals around the world.</p>
                          </div>
                    </div>
                    
                    <div className="col-md-3 col-sm-12 col-12">
                        <img src={process.env.PUBLIC_URL + '/assets/images/about-service.jpg'} alt="" className="img-fluid" />
                    </div>

                </div>
                  </div>

            </section>


            <section className="section-padding customer-feedback">
                  <div className="container">
                  <div className="row justify-content-center">
                        <div className="col-md-10 col-sm-12 col-12">
                            <h3 className="text-center team_title mb-4">Our Mission</h3>
                             <div className="missionbox">
                                 <p>To continue to offer punctual and cost-effective proofreading, editing, and writing solutions for our customers. We stake our growth and competitive advantage on our ability to listen to our customers and exceed their service expectations.</p>
                                 <blockquote>Words are not merely words; they are the embodiment of power.
<span className="autor">Chin-Ning Chu, author of Thick Face, Black Heart</span></blockquote>
<p>The REAL WordsRU difference is in our editors and chief editors.</p>
<p>This is because we employ the services of the finest editors, who have proven themselves through years of professional and educational experience. Please click here to review and select your editor of choice.</p>
 
                             </div>
                        </div>
                    </div>
                   </div>
            </section> 


             <section className="section-padding">
              <div className="container">
              <div className="row justify-content-center">
                      <div className="col-lg-8 col-md-12 col-sm-12 col-12 text-center mb-4">
                       <h2 className="team_title mb-3">Your Dedicated Team Of Expert Editors</h2>
                       <div className="subdescription"><p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p></div>
                      </div>  
                   </div>
                <div className="row">
                    <div className="col-lg-3 col-md-4 col-ms-2 col-12 mb-4">
                          <div className="number-counter">
                                <p>Over</p>
                                <h2>750</h2>
                                <h6>Highly Educated Editors</h6>
                           </div>    
                    </div>

                    <div className="col-lg-3 col-md-4 col-ms-2 col-12 mb-4">
                          <div className="number-counter">
                                 <p>Over</p>
                                <h2>100</h2>
                                <h6>hold PhDs</h6>
                           </div>    
                    </div>

                     

                    <div className="col-lg-3 col-md-4 col-ms-2 col-12 mb-4">
                          <div className="number-counter">
                                <h2>97%</h2>
                                <h6>Hold Graduate Degree</h6>
                           </div>    
                    </div>

                    <div className="col-lg-3 col-md-4 col-ms-2 col-12 mb-4">
                          <div className="number-counter">
                               <p>Qualified in over</p>
                                <h2>200</h2>
                                <h6>subject areas</h6>
                           </div>    
                    </div>

                </div>
              </div>
         </section>     


          <section className="section-padding faq-section">
               <div className="container">
               
               <div className="row">
                    <div className="col-12 text-center mb-4">
                          <div className="team_title"><h3>Questions About Our Services</h3></div>
                    </div>
                </div>  

                <div className="row  justify-content-center mt-3">
                <div className="col-md-4 col-sm-12 col-12 mb-4">
                        <div className="custom-card">
                              <Link to="/faq"><div className="icon-box"><i className="fa fa-globe"></i></div>
                              <h4>English as a Second Language</h4></Link>
                        </div>
                    </div>

                    <div className="col-md-4 col-sm-12 col-12 mb-4">
                        <div className="custom-card">
                              <Link to="/faq"><div className="icon-box"><i className="fa fa-bank"></i></div>
                              <h4>Academic</h4></Link>
                        </div>
                    </div>

                    <div className="col-md-4 col-sm-12 col-12 mb-4">
                        <div className="custom-card">
                        <Link to="/faq"> <div className="icon-box"><i className="fa fa-graduation-cap"></i></div>
                              <h4>Student</h4></Link>
                        </div>
                    </div>

                    <div className="col-md-4 col-sm-12 col-12 mb-4">
                        <div className="custom-card">
                           <Link to="/faq"> <div className="icon-box"><i className="fa fa-book"></i></div>
                              <h4>Author</h4></Link>
                        </div>
                    </div>

                    <div className="col-md-4 col-sm-12 col-12 mb-4">
                        <div className="custom-card">
                          <Link to="/faq"><div className="icon-box"><i className="fa fa-building"></i></div>
                              <h4>Business or Corporate</h4></Link>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12 col-12 mb-4">
                        <div className="custom-card">
                         <Link to="/faq">  <div className="icon-box"><i className="fa fa-user"></i></div>
                              <h4>Personal</h4></Link>
                        </div>
                    </div>
                    
                </div>  

                {/* <div className="row justify-content-center">
                    <div className="col-md-8 col-sm-12 col-12">
                    <div className="accordion-section clearfix mt-3" aria-label="Question Accordions">
				  <div className="container">
				  
					  <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">

                          <div className="panel panel-default mb-3">
                              <div className="panel-heading p-3 " role="tab" id="heading0">
                                  <h3 className="panel-title">
                                      <a className="collapsed" role="button" title="" data-toggle="collapse" data-parent="#accordion" href="#collapse0" aria-expanded="true" aria-controls="collapse0">
                                           What kind of academic work do you edit?
                                      </a>
                                  </h3>
                              </div>
                              <div id="collapse0" className="panel-collapse collapse" role="tabpanel" aria-labelledby="heading0">
                                  <div className="panel-body mb-4">
                                      <p>The time required for an edit depends on the length and condition of the project, but we will make every effort to accommodate your deadline. WordsRU editors can usually return documents of 2000 words or less in 24 hours (upon receipt of payment), we also offer expedited service if you need an edit done more quickly.</p>

                                  </div>
                              </div>
                          </div>

                          <div className="panel panel-default  mb-3">
                              <div className="panel-heading p-3" role="tab" id="heading1">
                                  <h3 className="panel-title">
                                      <a className="collapsed" role="button" title="" data-toggle="collapse" data-parent="#accordion" href="#collapse1" aria-expanded="true" aria-controls="collapse1">
                                      What kinds of errors will you fix?
                                      </a>
                                  </h3>
                              </div>
                              <div id="collapse1" className="panel-collapse collapse" role="tabpanel" aria-labelledby="heading1">
                                  <div className="panel-body mb-4">
                                  <p>The time required for an edit depends on the length and condition of the project, but we will make every effort to accommodate your deadline. WordsRU editors can usually return documents of 2000 words or less in 24 hours (upon receipt of payment), we also offer expedited service if you need an edit done more quickly.</p>

                                  </div>
                              </div>
                          </div>


                          <div className="panel panel-default  mb-3">
                              <div className="panel-heading p-3 " role="tab" id="heading2">
                                  <h3 className="panel-title">
                                      <a className="collapsed" role="button" title="" data-toggle="collapse" data-parent="#accordion" href="#collapse2" aria-expanded="true" aria-controls="collapse2">
                                      How quickly can you edit my paper?
                                      </a>
                                  </h3>
                              </div>
                              <div id="collapse2" className="panel-collapse collapse" role="tabpanel" aria-labelledby="heading2">
                                  <div className="panel-body mb-4">
                                  <p>The time required for an edit depends on the length and condition of the project, but we will make every effort to accommodate your deadline. WordsRU editors can usually return documents of 2000 words or less in 24 hours (upon receipt of payment), we also offer expedited service if you need an edit done more quickly.</p>
                                  <p>Please remember that many academic documents benefit from consultation between the editor and the author. This is especially so for documents written by less-experienced students or by students and academics who have difficulty expressing themselves in writing (such as those for whom English is a second language). The consultation process can add great value to your project, but it also takes time. You should also allow yourself at least a day to review the edited paper and make any revisions.</p>
                                  </div>
                              </div>
                          </div>


                            <div className="panel panel-default  mb-3">
                              <div className="panel-heading p-3" role="tab" id="heading3">
                                  <h3 className="panel-title">
                                      <a className="collapsed" role="button" title="" data-toggle="collapse" data-parent="#accordion" href="#collapse3" aria-expanded="true" aria-controls="collapse3">
                                         How much will it cost me?
                                      </a>
                                  </h3>
                              </div>
                              <div id="collapse3" className="panel-collapse collapse" role="tabpanel" aria-labelledby="heading3">
                                  <div className="panel-body mb-4">
                                  <p>The time required for an edit depends on the length and condition of the project, but we will make every effort to accommodate your deadline. WordsRU editors can usually return documents of 2000 words or less in 24 hours (upon receipt of payment), we also offer expedited service if you need an edit done more quickly.</p>

                                  </div>
                              </div>
                          </div>        
					  
					                 </div>
				  
				                 </div>
		               		</div>
                    </div>
                </div> */}

                <div className="row">
                <div className="col-12 text-center mt-4">
                  <Link to="/faq" className="btn btn-dark rounded-0">View All FAQs</Link>
                </div>
              </div>

               </div>

         </section>
        {/* FAQ section display end here */}

      


        </>
    );
}

export default Aboutus;