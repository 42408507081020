import React from "react";

function Reviews(){
    return(
        <>
          <section className="inner-page section-themebg">
                <div className="container">
                <div className="row">
                    <div className="col-12 text-center">
                        <h3>Reviews</h3>
                    </div>
                </div>
                </div>
            </section>   


            <section className="section-padding">
                <div className="container">
                <div className="row">
                    <div className="col-12 ">
                         dsf
                    </div>
                </div>
                </div>
            </section>  

        
        </>
    );
}

export default Reviews;