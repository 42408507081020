import React, {useState} from "react";
import { Link } from "react-router-dom";
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';
import { Navigation, Autoplay  } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';

import '../../assets/css/services.css';

import img1 from '../../assets/images/before.png';
import img2 from '../../assets/images/after.png';


function ForAcademics(){
    const [value, onChange] = useState(new Date());

    const [activeTab, setActiveTab] = useState(0);

    const handleTabClick = (index) => {
      setActiveTab(index);
    };
 

    return(
        <>

    <section className="inner-page section-themebg">
        <div className="container">
        <div className="row">
            <div className="col-12 text-center">
                <h3>For Academics</h3>
            </div>
        </div>
        </div>
    </section>


    <section className="section-padding">
         <div className="container">
            <div className="row">
               <div className="col-md-8 col-sm-12 col-12">
                    <h1 className="service_main_title mb-4">Academic Editing and Proofreading Service</h1>
                    <p>Wordan has helped students and academic submit flawlesstheses, dissertation Journal articles, research papers, barm papers, conference posters and other types of academic writing for more than 18 years.</p>
<p>Our highly deure compliance with numerous citation and university sty requirements, and accres the unique needs of those for whom English is a second language</p>
                 <div className="btn-box"> <Link to="#" className="btn btn-dark rounded-0">Try for Free</Link></div>
                </div>
               <div className="col-md-4 col-sm-12 col-12">
                <div className="comparisonimage">
                        <ReactCompareSlider
                        itemOne={<ReactCompareSliderImage src={img1} srcSet={img1} alt="Before Image" />}
                        itemTwo={<ReactCompareSliderImage src={img2} srcSet={img2} alt="After Image" />}
                        />
                </div>
              

                        {/* <ImageSlider
                            image1={img1}
                            image2={img2}
                            onSlide={() => {
                            console.log("sliding");
                            }}
                        /> */}
               </div>
            </div>

            <div className="row mt-5">
                  <div className="col-md-4">
                      <img src={process.env.PUBLIC_URL + '/assets/images/image.png'} alt=" " className="img-fluid"/>
                  </div>  
                <div className="col-md-8 col-sm-12 col-12">
                     <h2 className="mb-4">Our Professional Editors Have Helped Clients Get Published in the Following Subjects Arthenice</h2>
                     <p>Our professional proofreading service includes the following:</p>
                     <ul className="feature_list">
                        <li><i className="fa-solid fa-circle-check"></i>Correcting grammar, spelling, and punctuation errors</li>
                        <li><i className="fa-solid fa-circle-check"></i>Correcting grammar, spelling, and punctuation errors</li>
                        <li><i className="fa-solid fa-circle-check"></i>Correcting grammar, spelling, and punctuation errors</li>
                        <li><i className="fa-solid fa-circle-check"></i>Correcting grammar, spelling, and punctuation errors</li>
                     </ul>
                </div>
            </div>
         </div>
    </section>

    <section className="trusted-partner">
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                      <div className="mb-4 text-center"><h3>We server students and academics from</h3>
                      </div>
                    <Swiper
                      navigation={true} modules={[Autoplay, Navigation]}
                      spaceBetween={30}
                      autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                      }}
                      breakpoints={{
                        640: {
                          slidesPerView: 2,
                          spaceBetween: 20,
                        },
                        768: {
                          slidesPerView: 3,
                          spaceBetween: 20,
                        },
                        1024: {
                          slidesPerView: 5,
                          spaceBetween: 30,
                        },
                      }}
                      loop={true}
                      className="mySwiper-brands"             
                    >
                      <SwiperSlide><div className="img-box"><img src={process.env.PUBLIC_URL + '/assets/images/philips.png'} alt=" " className="img-fluid" /></div></SwiperSlide>
                      <SwiperSlide><div className="img-box"><img src={process.env.PUBLIC_URL + '/assets/images/horvard.png'} alt=" " className="img-fluid" /></div></SwiperSlide>
                      <SwiperSlide><div className="img-box"><img src={process.env.PUBLIC_URL + '/assets/images/philips.png'}  alt=" " className="img-fluid" /></div></SwiperSlide>
                      <SwiperSlide><div className="img-box"><img src={process.env.PUBLIC_URL + '/assets/images/horvard.png'} alt=" " className="img-fluid" /></div></SwiperSlide>
                      <SwiperSlide><div className="img-box"><img src={process.env.PUBLIC_URL + '/assets/images/philips.png'}  alt=" " className="img-fluid" /></div></SwiperSlide>
                      <SwiperSlide><div className="img-box"><img src={process.env.PUBLIC_URL + '/assets/images/horvard.png'} alt=" " className="img-fluid" /></div></SwiperSlide>
                      
                    </Swiper>
                    </div>
                  </div>
                </div>
         </section>

         <section className="section-padding tabbing-section">
         <div className="container">
            <div className="row mb-5">
                <div className="col-12 text-center text-white mb-4"><h2>Our Areas of Expertise</h2></div>
               <div className="col-md-3 col-sm-12 col-12">
               <div className="total_steps">
                    <ul className="tab-list">
                        <li
                        className={`tab-item ${activeTab === 0 ? 'active' : ''}`}
                        onClick={() => handleTabClick(0)}
                        >
                        Essay Editing
                        </li>
                        <li
                        className={`tab-item ${activeTab === 1 ? 'active' : ''}`}
                        onClick={() => handleTabClick(1)}
                        >
                        Dissertation Editing
                        </li>




                        <li
                        className={`tab-item ${activeTab === 2 ? 'active' : ''}`}
                        onClick={() => handleTabClick(2)}
                        >
                        Thesis Editing
                        </li>

                        <li
                        className={`tab-item ${activeTab === 3 ? 'active' : ''}`}
                        onClick={() => handleTabClick(3)}
                        > Report Editing
                        </li>
                        <li
                        className={`tab-item ${activeTab === 4 ? 'active' : ''}`}
                        onClick={() => handleTabClick(4)}
                        > Academic ESL
                        </li>



                        <li
                        className={`tab-item ${activeTab === 5 ? 'active' : ''}`}
                        onClick={() => handleTabClick(5)}
                        > Research Paper Editing
                        </li>
                        <li
                        className={`tab-item ${activeTab === 6 ? 'active' : ''}`}
                        onClick={() => handleTabClick(6)}
                        > Journal Article Editing
                        </li>
                        <li
                        className={`tab-item ${activeTab === 7 ? 'active' : ''}`}
                        onClick={() => handleTabClick(7)}
                        > Personal Statement Editing
                        </li>
                         
                    </ul>
                    </div>
               </div>

               <div className="col-md-9 col-sm-12 col-12">
               <div className="tab-content">
                        <div className={`tab-pane ${activeTab === 0 ? 'active' : ''}`}>
                           <div className="content_card">
                              <h3>Essay Editing and Proofreading</h3>
                              <p>WordsRU provides online, professional academic editing and proofreading services for all academic disciplines and fields.</p>

<p>Our highly qualified academic editors ensure compliance with numerous citation and university style requirements and also address the unique needs of those for whom English is a second language (ESL). We work with students at all levels, as well as postgraduates and faculty.</p>
                           </div>
                        </div>
                        <div className={`tab-pane ${activeTab === 1 ? 'active' : ''}`}>
                            <div className="content_card">
                            <h3>Dissertation Editing</h3>
                              <p>WordsRU provides online, professional academic editing and proofreading services for all academic disciplines and fields.</p>

<p>Our highly qualified academic editors ensure compliance with numerous citation and university style requirements and also address the unique needs of those for whom English is a second language (ESL). We work with students at all levels, as well as postgraduates and faculty.</p>
                            </div>
                        </div>
                        <div className={`tab-pane ${activeTab === 2 ? 'active' : ''}`}>
                        <div className="content_card">
                        <h3>Thesis Editing</h3>
                              <p>WordsRU provides online, professional academic editing and proofreading services for all academic disciplines and fields.</p>

<p>Our highly qualified academic editors ensure compliance with numerous citation and university style requirements and also address the unique needs of those for whom English is a second language (ESL). We work with students at all levels, as well as postgraduates and faculty.</p>
                            </div>
                        </div>

                        <div className={`tab-pane ${activeTab === 3 ? 'active' : ''}`}>
                        <div className="content_card">
                        <h3>Report Editing</h3>
                              <p>WordsRU provides online, professional academic editing and proofreading services for all academic disciplines and fields.</p>

<p>Our highly qualified academic editors ensure compliance with numerous citation and university style requirements and also address the unique needs of those for whom English is a second language (ESL). We work with students at all levels, as well as postgraduates and faculty.</p>
                            </div>
                        </div>

                        <div className={`tab-pane ${activeTab === 4 ? 'active' : ''}`}>
                        <div className="content_card">
                        <h3>Academic ESL</h3>
                              <p>WordsRU provides online, professional academic editing and proofreading services for all academic disciplines and fields.</p>

<p>Our highly qualified academic editors ensure compliance with numerous citation and university style requirements and also address the unique needs of those for whom English is a second language (ESL). We work with students at all levels, as well as postgraduates and faculty.</p>
                            </div>
                        </div>

                        <div className={`tab-pane ${activeTab === 5 ? 'active' : ''}`}>
                        <div className="content_card">
                        <h3>Research Paper Editing</h3>
                              <p>WordsRU provides online, professional academic editing and proofreading services for all academic disciplines and fields.</p>

<p>Our highly qualified academic editors ensure compliance with numerous citation and university style requirements and also address the unique needs of those for whom English is a second language (ESL). We work with students at all levels, as well as postgraduates and faculty.</p>
                            </div>
                        </div>


                        <div className={`tab-pane ${activeTab === 6 ? 'active' : ''}`}>
                        <div className="content_card">
                        <h3>Journal Article Editing</h3>
                              <p>WordsRU provides online, professional academic editing and proofreading services for all academic disciplines and fields.</p>

<p>Our highly qualified academic editors ensure compliance with numerous citation and university style requirements and also address the unique needs of those for whom English is a second language (ESL). We work with students at all levels, as well as postgraduates and faculty.</p>
                            </div>
                        </div>

                        <div className={`tab-pane ${activeTab === 7 ? 'active' : ''}`}>
                        <div className="content_card">
                        <h3>Personal Statement Editing</h3>
                              <p>WordsRU provides online, professional academic editing and proofreading services for all academic disciplines and fields.</p>

<p>Our highly qualified academic editors ensure compliance with numerous citation and university style requirements and also address the unique needs of those for whom English is a second language (ESL). We work with students at all levels, as well as postgraduates and faculty.</p>
                            </div>
                        </div>

                        

                   </div>     
               </div>
            </div>


            </div>

  </section>

    <section className="section-padding customer-feedback">
          <div className="container">
              <div className="row">
                   <div className="col-md-7 col-sm-12 col-12">
                   <h2 className="service_sub_title mb-4"> A Dedicated Team
Of Expert Editors Working 24/7, 365 Days A Year</h2>
<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
 <div className="btn-box mt-4"><Link to="#" className="btn btn-dark rounded-0">Get a Quote</Link></div>
                   </div>

                   <div className="col-md-5 col-sm-12 col-12">
                        <ul className="teacher-list">
                                <li> <img src={process.env.PUBLIC_URL + '/assets/images/team/team-01.jpg'} alt="" /> <h4>Aimée</h4> <span><i className="fa-solid fa-circle text-primary"></i>London</span> <span><i className="fa-solid fa-circle text-success "></i>Engineering</span> </li>
                                <li> <img src={process.env.PUBLIC_URL + '/assets/images/team/team-01.jpg'} alt="" /> <h4>Brett</h4> <span><i className="fa-solid fa-circle text-primary"></i>Exeter</span> <span><i className="fa-solid fa-circle  text-success"></i>Biology, Chemistry</span> </li>
                                <li> <img src={process.env.PUBLIC_URL + '/assets/images/team/team-03.jpg'} alt="" /> <h4>Jehane</h4> <span><i className="fa-solid fa-circle text-primary"></i>London</span> <span><i className="fa-solid fa-circle text-success "></i>Art, Painting</span> </li>
                                <li> <img src={process.env.PUBLIC_URL + '/assets/images/team/team-04.jpg'} alt="" /> <h4>Jehane</h4> <span><i className="fa-solid fa-circle text-primary"></i>Brighton & Hove</span> <span><i className="fa-solid fa-circle text-success "></i>Music, Singing</span> </li>
                                <li> <img src={process.env.PUBLIC_URL + '/assets/images/team/team-05.jpg'} alt="" /> <h4>Jehane</h4> <span><i className="fa-solid fa-circle text-primary"></i>London</span> <span><i className="fa-solid fa-circle text-success "></i>Engineering</span> </li>
                                <li> <img src={process.env.PUBLIC_URL + '/assets/images/team/team-01.jpg'} alt="" /> <h4>Jehane</h4> <span><i className="fa-solid fa-circle text-primary"></i>London</span> <span><i className="fa-solid fa-circle text-success "></i>Engineering</span> </li>
                                <li> <img src={process.env.PUBLIC_URL + '/assets/images/team/team-02.jpg'} alt="" /> <h4>Jehane</h4> <span><i className="fa-solid fa-circle text-primary"></i>London</span> <span><i className="fa-solid fa-circle text-success "></i>Engineering</span> </li>
                                <li> <img src={process.env.PUBLIC_URL + '/assets/images/team/team-02.jpg'} alt="" /> <h4>Jehane</h4> <span><i className="fa-solid fa-circle text-primary"></i>London</span> <span><i className="fa-solid fa-circle text-success "></i>Engineering</span> </li>
                               
                        </ul> 


                        
                   </div>

              </div>
          </div>  
    </section>


    <section className="section-padding">
         <div className="container">
            <div className="row mb-5">
                <div className="col-12"><h2 className="service_sub_title">How We Work</h2></div>
            </div>

            <div className="row">
                <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                     <div className="work_box">
                          <div className="img-box"><img src={process.env.PUBLIC_URL + '/assets/images/delivery.svg'} alt="" /></div>
                          <h3 className="mb-4">Lightning-Fast Delivery</h3>
                          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                     </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                    <div className="work_box">
                          <div className="img-box"><img src={process.env.PUBLIC_URL + '/assets/images/price.svg'} alt="" /></div>
                          <h3 className="mb-4">Great Pricing</h3>
                          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                     </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                    <div className="work_box">
                          <div className="img-box"><img src={process.env.PUBLIC_URL + '/assets/images/format.svg'} alt="" /></div>
                          <h3 className="mb-4">All Major Formats</h3>
                          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                     </div>
                </div>

                <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                    <div className="work_box">
                          <div className="img-box"><img src={process.env.PUBLIC_URL + '/assets/images/clock.svg'} alt="" /></div>
                          <h3 className="mb-4">24-Hour Support</h3>
                          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                     </div>
                </div>

                 


            </div>
         </div>

    </section>




    <section className="section-padding bg-success">
              <div className="container">
                   <div className="row">
                      <div className="col-12 text-center">
                       <h2 className="team_title mb-4 text-white">Request Call Back</h2>
                      </div>  
                   </div>

                  <div className="row justify-content-center">
                      <div className="col-md-8 col-sm-12 col-12">
                           <div className="requestform">
                              <div className="row justify-content-center">
                                  <div className="col-md-6 col-sm-12 col-12">
                                      <div className="form-group">
                                          <label>Full Name</label>
                                          <input type="text" className="form-control"  />
                                      </div>
                                  </div>
                                  <div className="col-md-6 col-sm-12 col-12">
                                      <div className="form-group">
                                          <label>Email Address</label>
                                          <input type="email" className="form-control"  />
                                      </div>
                                  </div>

                                  <div className="col-md-6 col-sm-12 col-12">
                                      <div className="form-group">
                                          <label>Phone Number</label>
                                          <input type="text" className="form-control"  />
                                      </div>
                                  </div>
                                  
                                  <div className="col-md-6 col-sm-12 col-12">
                                      <div className="form-group">
                                          <label>Time/date for a call</label>
                                          <DateTimePicker  className="form-control" onChange={onChange} value={value} />
                                      </div>
                                  </div>
                                  <div className="col-md-12 col-sm-12 col-12">
                                      <div className="form-group">
                                          <label>Company Name</label>
                                          <input type="text" className="form-control"  />
                                      </div>
                                  </div>
                                  <div className="col-md-12 col-sm-12 col-12">
                                      <div className="form-group text-right">
                                        <Link to="/" className="btn btn-dark rounded-0">Request Call Back</Link>
                                      </div>
                                  </div>
                                  
                                  
                            </div>
                           </div>
                      </div>
                  </div>

                  
              </div>

         </section>


  

        
        </>
    );
}

export default ForAcademics;