import React from 'react';
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import '../../assets/css/multistep.css';
 

 
//function ProgresQuote() {
  
const ProgresQuote = props => {
  var stepPercentage = 0;

  if (props.currentStep === 1) {
    stepPercentage = 0;
  } else if (props.currentStep === 2) {
    stepPercentage = 25;
   } else if (props.currentStep === 3) {
     stepPercentage = 50;
    } else if (props.currentStep === 4) {
      stepPercentage = 75;
   
  } else if (props.currentStep === 5) {
    stepPercentage = 100;
 }
  else {
    stepPercentage = 0;
  }

  return (
    <ProgressBar percent={stepPercentage}>
      
      <Step>
        {({ accomplished, index }) => (
           <>
          <div
            className={`indexedStep ${accomplished ? "accomplished" : null}`}
          >
           <div className={`circlebox indexedStep ${accomplished ? "accomplished" : null}`}> <span className="icon-circle-o"></span>
           <span className={`indexing_number indexedStep ${accomplished ? "accomplished" : null}`}>{index + 1}</span></div> 
           
          </div>
          <h3 className={`stepname indexedStep ${accomplished ? "accomplished" : null}`}>Upload Document</h3>
          </>
        )}

        
      </Step>

      <Step>
        {({ accomplished, index }) => (
           <>
          <div
            className={`indexedStep ${accomplished ? "accomplished" : null}`}
          >
           <div className={`circlebox indexedStep ${accomplished ? "accomplished" : null}`}> <span className="icon-circle-o"></span>
           <span className={`indexing_number indexedStep ${accomplished ? "accomplished" : null}`}>{index + 1}</span></div> 
           
          </div>
          <h3 className={`stepname indexedStep ${accomplished ? "accomplished" : null}`}>Your Details</h3>
          </>
        )}

        
      </Step>
      <Step>
        {({ accomplished, index }) => (
          <>
            <div
            className={`indexedStep ${accomplished ? "accomplished" : null}`}
          >
             <div className={`circlebox indexedStep ${accomplished ? "accomplished" : null}`}> <span className="icon-circle-o"></span>
             <span className={`indexing_number indexedStep ${accomplished ? "accomplished" : null}`}>{index + 1}</span></div> 


          </div>
            <h3 className={`stepname indexedStep ${accomplished ? "accomplished" : null}`}>Job Detail</h3>
          </>
          
        )}
      </Step>
       <Step>
        {({ accomplished, index }) => (
           <>
            <div className={`indexedStep ${accomplished ? "accomplished" : null}`}>           
                <div className={`circlebox indexedStep ${accomplished ? "accomplished" : null}`}> <span className="icon-circle-o"></span>
                <span className={`indexing_number indexedStep ${accomplished ? "accomplished" : null}`}>{index + 1}</span></div> 
            </div>
            <h3 className={`stepname indexedStep ${accomplished ? "accomplished" : null}`}>Select Service</h3>
          </>
        )}
      </Step> 

       <Step>
        {({ accomplished, index }) => (
           <>
            <div className={`indexedStep ${accomplished ? "accomplished" : null}`}>           
                <div className={`circlebox indexedStep ${accomplished ? "accomplished" : null}`}> <span className="icon-circle-o"></span>
                <span className={`indexing_number indexedStep ${accomplished ? "accomplished" : null}`}>{index + 1}</span></div> 
            </div>
            <h3 className={`stepname indexedStep ${accomplished ? "accomplished" : null}`}>Place Order</h3>
          </>
        )}
      </Step>  
    </ProgressBar>
  );
};

export default ProgresQuote;