import React, { useState, useRef, useCallback, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../assets/css/pricing.css";
import Dropzone from "react-dropzone";
import { Editor } from "@tinymce/tinymce-react";
import { axiosRequest } from "../../api";
import { baseURL } from "../../constant";
import ReactHtmlParser from "react-html-parser";
import Cookies from "universal-cookie";
import moment from "moment";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutFormPricing from './submitjob/CheckoutFormPricing';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles

function PricingPage(props) {
  const cookies = new Cookies();
  cookies.set("editorFees", "5", { path: "/" });

  const stripePromise = loadStripe('pk_test_51Nq6CmSCmmwBLcP64DjmaK2NpkiqZFZV2YUMuoMb076I6qJny1LHLOcd1YUhNFUZNETwIlLfBP0Vka8JEQO8rFEG00sdaMhFZT');
  const [clientSecret, setClientSecret] = useState("");
  const [clientSecretID, setclientSecretID] = useState("");
  const [file, setFileName] = useState("");
  const [isFile, setIsFile] = useState("");
  const [content, setContent] = useState('');

  const items = [{ amount: '11' }];
  const currency = cookies.get("currency");

  const editorRef = useRef(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [planList, setplanList] = useState([]);
  const [name, setName] = useState("admin");
  const [email, setEmail] = useState("testdummy@gmail.com");
  const [wordCount, setWordcount] = useState("250");
  const [message, setMessage] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [editorval, setEditorValue] = useState("");
  const [jobType, setJobType] = useState("");
  const [jobPrice, setJobPrice] = useState("");
  const [setting, setSetting] = useState("");
  const [deliveryCharge, setdeliveryCharge] = useState("");
  const [deliveryType, setdeliveryType] = useState("Regular Delivery");
  const [deliverydate, setDeliverydate] = useState("");
  const [isCheckout, setIsCheckout] = useState(0);
  const [isJobDetails, setisJobDetails] = useState(1);
  const [delRegular, setDelRegular] = useState("");
  const [delFast, setDelFast] = useState("");
  const [delSuperFast, setDelSuperFast] = useState("");
  const [selectedDelType, setselectDelType] = useState("");
  const [selectedJobType, setselectJobType] = useState("");
  const [showUploadInfo, setShowUploadInfo] = useState(true);
  const checkLogin = cookies.get("islogin");
  const timezoneInput = "Asia/Calcutta";

  useEffect(() => {
    const checkLogin = cookies.get("islogin");
    const radioValue = cookies.get("radioVal");
    const lengthValue = cookies.get("length");
    const EditorValue = cookies.get("editorVal");
    const deliveryDate = cookies.get("deleverydate");
    const jobPrice = cookies.get("jobPrice");
    const deleverycharge = cookies.get("deleverycharge");
    const setting = cookies.get("setting");
    const userName = cookies.get("userName");
    const email = cookies.get("userEmail");
    const comefrom = cookies.get("comeFrom");
    const checkoutJobTYpe = cookies.get("checkoutjobType");
    const deleverytype = cookies.get("deleverytype");
    const planList = cookies.get("planList");
    const fileName = cookies.get("fileName");
      

    setName(userName);
    setEmail(email);
    if (checkLogin && comefrom === "login") {
      setSelectedOption(radioValue);
      setWordcount(lengthValue);
      setEditorValue(EditorValue ? EditorValue : "");
      setDeliverydate(deliveryDate);
      setJobType(checkoutJobTYpe);
      setJobPrice(jobPrice);
      setdeliveryCharge(deleverycharge);
      setSetting(setting);
      setIsCheckout(1);
      setisJobDetails(0);
      setFileName(fileName);
      if (planList) {
        setplanList(planList);
      }
      if (deleverytype === "Regular Delivery") {
        setselectDelType("Regular");
      } else if (deleverytype === "Fast Delivery") {
        setselectDelType("Fast");
      } else if (deleverytype === "Super Fast Delivery") {
        setselectDelType("SuperFast");
      }
      if (checkoutJobTYpe === "Basic Service") {
        setselectJobType(1);
      } else if (checkoutJobTYpe === "Plus Service") {
        setselectJobType(2);
      }
    }
  }, []);
  const handleChange = (event) => {
      const { name, value } = event.target;
      const cookies = new Cookies();
      console.log(name, value, 73);
      const curr = "USD";
    
    if(name === "name"){
      cookies.set("userName", value, { path: "/" });
    }
    if(name === "email"){
      cookies.set("userEmail", value, { path: "/" });
    }
  };
  // set Delivery date
  useEffect(() => {
    const currentDate = moment();
    let delDate = "";
    const val1 = "1";
    const val2 = "2";
    const val3 = "3";
    if (val1 === "1") {
      delDate = currentDate.clone().add(3, "days");
      setDelRegular(delDate.format("DD MMM YYYY "));
    }
    if (val2 === "2") {
      delDate = currentDate.clone().add(2, "days");
      setDelFast(delDate.format("DD MMM YYYY "));
    }
    if (val3 === "3") {
      delDate = currentDate.clone().add(1, "days");
      setDelSuperFast(delDate.format("DD MMM YYYY "));
    }
  }, []);

  // get service plan
  useEffect(() => {
    const checkLogin = cookies.get("islogin");
    const comefrom = cookies.get("comeFrom");
    async function getPlan() {
      const response = await axiosRequest.get(`${baseURL}/getPlanList`);

      // After fetching data stored it in posts state.
      setplanList(response.data);
    }

    if (checkLogin !== true && comefrom !== "login") {
      const loadPost = async () => {
        // Till the data is fetch using API
        // the Loading page will show.
        setLoading(true);
        // Await make wait until that
        // promise settles and return its result
        const response = await axiosRequest.get(`${baseURL}/getPlanList`);

        // After fetching data stored it in posts state.
        setplanList(response.data);
        setJobType(response.data[0]["title"]);
        setJobPrice(response.data[0]["price"]);
        cookies.set("jobPrice", response.data[0]["price"], { path: "/" });
        cookies.set("planList", response.data, { path: "/" });
        // Closed the loading page
        setLoading(false);
      };

      // Call the function
      loadPost();

      const loadSetting = async () => {
        // Till the data is fetch using API
        // the Loading page will show.
        setLoading(true);
        // Await make wait until that
        // promise settles and return its result
        const response = await axiosRequest.get(`${baseURL}/getsetSetting`);

        // After fetching data stored it in posts state.
        setSetting(response.data);
        cookies.set("setting", response.data, { path: "/" });
        setdeliveryCharge(response.data.regular_delivery_amount);
        cookies.set("deleverycharge", response.data.regular_delivery_amount, {
          path: "/",
        });
        // Closed the loading page
        setLoading(false);
      };
      loadSetting();
      const items = [{ amount: cookies.get("price") }];

    const loadSecretKey = async () => {
      // Till the data is fetch using API
      // the Loading page will show.
      setLoading(true);
      // Await make wait until that
      // promise settles and return its result

    //  const fetchClientSecret = async () => {
        try {
            const response = await axiosRequest.get(`${baseURL}/getloadSecretKey`, {
                items,
            });
            console.log(response.data.clientSecret)
            cookies.set("stripeToken", response.data.clientSecret, { path: "/" });
            cookies.set("clientSecretID", response.data.clientSecretID, { path: "/" });
            setClientSecret(response.data.clientSecret);
            setclientSecretID(response.data.clientSecretID);
        } catch (error) {
            console.log(error);
        }
    //}

/*
      const response = await axiosRequest.get(`${baseURL}/getloadSecretKey`);
      console.log(response.data.clientSecret);
      // After fetching data stored it in posts state.
      setSecretKey(response.data.clientSecret);
      */
      // Closed the loading page
      setLoading(false);
    };
    loadSecretKey();
      

      axiosRequest
        .get(`${baseURL}/getMaxDateBeforeExpedited/250/UTC`)
        .then(function (response) {
          setDeliverydate(response.data);
          cookies.set("deleverydate", response.data, { path: "/" });
        })
        .catch(function (error) {
          console.log(error);
        });
      getPlan();
    }
  }, []);

  // Calculate price on words lenght
  let price;
  let superPrice;
  let ratio;
  let editorFees;
  if (wordCount >= 0 && wordCount <= 250) {
    price = 12.5;
    superPrice = 20;
    ratio = 1;
    editorFees = 5;
  } else if (wordCount > 250 && wordCount <= 500) {
    price = 25;
    superPrice = 35;
    ratio = 1;
    editorFees = 10;
  } else if (wordCount > 500 && wordCount <= 1000) {
    price = 35;
    superPrice = 45;
    ratio = 1;
    editorFees = 12.5;
  } else if (wordCount > 1000 && wordCount <= 1500) {
    price = 40;
    superPrice = 60;
    ratio = 1;
    editorFees = 14;
  } else if (wordCount > 1500 && wordCount <= 2500) {
    price = wordCount * 0.008 * 3.25;
    superPrice = wordCount * 0.011 * 3.35;
    ratio = 3.25;
    editorFees = wordCount * 0.008;
  } else if (wordCount >= 2501 && wordCount <= 25000) {
    price = wordCount * 0.007 * 3.2;
    superPrice = wordCount * 0.011 * 3.3;
    ratio = 3.2;
    editorFees = wordCount * 0.007;
  } else if (wordCount >= 25001 && wordCount <= 50000) {
    price = wordCount * 0.007 * 3;
    superPrice = wordCount * 0.011 * 3.1;
    ratio = 3;
    editorFees = wordCount * 0.007;
  } else if (wordCount > 50000) {
    price = wordCount * 0.007 * 2.9;
    superPrice = wordCount * 0.011 * 3;
    ratio = 2.9;
    editorFees = wordCount * 0.007;
  } else {
    price = 0;
    ratio = 1;
    editorFees = 5;
  }
  price = price?.toFixed(2);
  superPrice = superPrice?.toFixed(2);
  editorFees = editorFees?.toFixed(2);

  cookies.set("price", price, { path: "/" });


  const handleShowCheckout = () => {
    // check login or not
    const checkLogin = cookies.get("islogin");

    uploadFile();

    if (checkLogin === undefined) {
      setIsCheckout(1);
      setisJobDetails(0);

     // navigate("/login");
      //cookies.set("loginFrom", "Price", { path: "/" });
    } else {
      setIsCheckout(1);
      setisJobDetails(0);
    }
  };

  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
    cookies.set("radioVal", event.target.value, { path: "/" });
  };

  // on change onDrop
  /*const onDrop = useCallback((acceptedFiles) => {
    setFileName(acceptedFiles);
    cookies.set("fileName", acceptedFiles, { path: "/" });
  }, []);*/

  const onDrop = useCallback(acceptedFiles => {
    console.log(acceptedFiles[0]);
    //setFileName(acceptedFiles)
    setFileName((prevFiles) => [ ...acceptedFiles]); // Add new files to state

    setIsFile(acceptedFiles);
  }, []);
   // Remove a file from the list
   const handleRemoveFile = (indexToRemove) => {
    setIsFile((files) =>
      files.filter((_, index) => index !== indexToRemove)
    );
    setFileName((files) =>
      files.filter((_, index) => index !== indexToRemove)
    );
  };

  const onJobChanged = (event) => {
    const index1 = event.target.value - 1;
    setJobType(planList[index1]["title"]);
    setJobPrice(event.target.value === "1" ? price : superPrice);
    cookies.set("jobPrice", event.target.value === "1" ? price : superPrice, {
      path: "/",
    });
    cookies.set("jobType", planList[index1]["title"], { path: "/" });
    cookies.set("checkoutjobType", planList[index1]["title"], { path: "/" });
    setselectJobType(event.target.value);
  };

  const onDeleveryTypeChange = (event) => {
    const deleverytypeval = event.target.value;
    if (deleverytypeval == 1) {
      setselectDelType("Regular");
      setdeliveryType("Regular Delivery");
      setDeliverydate(delRegular);
      setdeliveryCharge(setting.regular_delivery_amount);
      cookies.set("deleverycharge", setting.regular_delivery_amount, {
        path: "/",
      });
      cookies.set("deleverytype", "Regular Delivery", { path: "/" });
    } else if (deleverytypeval == 2) {
      setselectDelType("Fast");
      setdeliveryType("Fast Delivery");
      setDeliverydate(delFast);
      setdeliveryCharge(setting.fast_delivery_amount);
      cookies.set("deleverycharge", setting.fast_delivery_amount, {
        path: "/",
      });
      cookies.set("deleverytype", "Fast Delivery", { path: "/" });
    } else {
      setselectDelType("SuperFast");
      setdeliveryType("Super Fast Delivery");
      setDeliverydate(delSuperFast);
      setdeliveryCharge(setting.superfast_delivery_amount);
      cookies.set("deleverycharge", setting.superfast_delivery_amount, {
        path: "/",
      });
      cookies.set("deleverytype", "Super Fast Delivery", { path: "/" });
    }
  };

  const handleChangeRange = (event) => {
    setWordcount(event.target.value);
    cookies.set("length", event.target.value, { path: "/" });
    const wordCount = event.target.value;
    console.log(wordCount);
    let pp;
    let ratio;
    let editorFees;
    if (wordCount >= 0 && wordCount <= 250) {
      pp = 12.5;
      ratio = 1;
      editorFees = 5;
    } else if (wordCount > 250 && wordCount <= 500) {
      pp = 25;
      ratio = 1;
      editorFees = 10;
    } else if (wordCount > 500 && wordCount <= 1000) {
      pp = 35;
      ratio = 1;
      editorFees = 12.5;
    } else if (wordCount > 1000 && wordCount <= 1500) {
      pp = 40;
      ratio = 1;
      editorFees = 14;
    } else if (wordCount >= 1500 && wordCount <= 2500) {
      pp = wordCount * 0.008 * 3.25;
      ratio = 3.25;
      editorFees = wordCount * 0.008;
    } else if (wordCount >= 2501 && wordCount <= 25000) {
      pp = wordCount * 0.007 * 3.2;
      ratio = 3.2;
      editorFees = wordCount * 0.007;
    } else if (wordCount >= 25001 && wordCount <= 50000) {
      pp = wordCount * 0.007 * 3;
      ratio = 3;
      editorFees = wordCount * 0.007;
    } else if (wordCount > 50000) {
      pp = wordCount * 0.007 * 2.9;
      ratio = 2.9;
      editorFees = wordCount * 0.007;
    } else {
      pp = 0;
      ratio = 1;
      editorFees = 5;
    }
    console.log(editorFees+'ccc');
    cookies.set("editorFees", editorFees, { path: "/" });

    axiosRequest
      .get(
        `${baseURL}/getMaxDateBeforeExpedited/` + event.target.value + `/UTC`
      )
      .then(function (response) {
        setDeliverydate(response.data);
        cookies.set("deleverydate", response.data, { path: "/" });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  let handleSubmit = async (e) => {
    const select = cookies.get("select");
    const jobType = cookies.get("jobType");
    const userId = cookies.get("userId");
    const fileID = cookies.get("fileID");
    const calwordCount = cookies.get("wordCount");
    const wordCount = cookies.get("length");
    const editorval = cookies.get("editorVal");
    const deliverydate = cookies.get("deleverydate");
    const jobPrice = cookies.get("jobPrice");
    const deliveryCharge = cookies.get("deleverycharge");
    const setting = cookies.get("setting");
    const email = cookies.get("userEmail");

    const data1 = {
      name: name,
      email: email,
      timeZones: timezoneInput,
      editorval: editorval ? editorval : "",
      servicetype: "1",
      file: file?.[0],
      select: select,
      jobType: jobType,
      delivery: deliverydate,
      fullAmountQuoted:
        parseInt(jobPrice) +
        parseInt(deliveryCharge) +
        parseInt(setting.tax_amount),
      wordCount: wordCount,
      userId: userId,
      fileID: fileID,
      calwordCount: calwordCount,
      deliveryPrice: deliveryCharge,
      editorFees,
      ratio,
    };
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    e.preventDefault();
    await axiosRequest
      .post(`${baseURL}/submitPricingJob`, data1, config)
      .then((response) => {
        if (response.data.success === true) {
          if (isCheckout === 1) {
            navigate("/thank-you");
          }
          setisJobDetails(0);
          setShowUploadInfo(false);
          setMessage(response.data.message);
          cookies.set("comeFrom", "", { path: "/" });
          cookies.set("length", "250", { path: "/" });
          cookies.set("editorFees", "5.00", { path: "/" });
          cookies.set("ratio", "1", { path: "/" });
          cookies.set("fileID", "", { path: "/" });
        } else {
          setMessage(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleContentChange = (value) => {
    setContent(value);

    // Remove HTML tags and extra spaces, then count words
    const text = value.replace(/<[^>]+>/g, '').trim();
    const words = text.length > 0 ? text.split(/\s+/).length : 0;
    setWordcount(words);
    setEditorValue(text);
    cookies.set("length", words, { path: "/" });
    
    cookies.set("editorFees", editorFees, { path: "/" });

    cookies.set("editorVal", text, { path: "/" });

  };
  const hangleEditorChange = (editorState) => {
    var wordcount = editorState.target.plugins.wordcount.getCount();
    setWordcount(wordcount);
    setEditorValue(editorState.level.content);
    cookies.set("length", wordcount, { path: "/" });
    
    cookies.set("editorFees", editorFees, { path: "/" });

    cookies.set("editorVal", editorState.level.content, { path: "/" });
  };

  const uploadFile = () => {
    const data = { files: file };
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    axiosRequest
      .post(`${baseURL}/fileUpload`, data, config)
      .then((response) => {
        if (response.data.success === true) {
          cookies.set("fileID", response.data.fileID, { path: "/" });
          cookies.set("wordCount", response.data.wordCount, { path: "/" });
          
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const goBack = () => {
    setisJobDetails(1);
    setIsCheckout(0);
  };

  return (
    <>
      <section className="inner-page section-themebg">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h3>Upload</h3>
            </div>
          </div>
        </div>
      </section>

      <section className="section-padding">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8 col-sm-12 col-12 text-center">
              <h2 className="team_title">Great Pricing</h2>
              <div className="subdescription">
                <p>
                  Our affordable and transparent pricing is based on the exact
                  length of your document and the services you need.
                </p>
                Use the calculator below to choose your document format, length,
                and the services you require to get an exact price. You can also
                drag and drop/upload your document.{" "}
              </div>
            </div>
          </div>

          {/* <div className="row mt-5">
                    <div className="col-12 text-center">
                         
                    <ul className="pricingtab-selection">
                        <li className={activeTab === 0 ? 'active' : ''} onClick={() => handleTabClick(0)}><img src={process.env.PUBLIC_URL + '/assets/images/user.svg'} alt=" " />Individual</li>
                        <li className={activeTab === 1 ? 'active' : ''} onClick={() => handleTabClick(1)}><img src={process.env.PUBLIC_URL + '/assets/images/business.svg'} alt=" " />Business</li>
                        
                    </ul>
                    

                    </div>
                </div> */}
        </div>
      </section>

      {isJobDetails === 1 && (
        <section
          className="section-padding pt-0"
          id="upload-info"
          style={{ display: showUploadInfo ? "block" : "none" }}
        >
          <div className="container">
            <div className="row py-3">
              <div className="col-7 col-sm-8 content">
                <div className="formatmain-box">
                  <div className="title_box">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/images/service.svg"
                      }
                      alt="Format"
                    />{" "}
                    <h3>I want to</h3>
                  </div>

                  <div className="wrapper-radio">
                    <input
                      type="radio"
                      name="select"
                      id="option-1"
                      value="Enter Free Text"
                      checked={selectedOption === "Enter Free Text"}
                      onChange={handleRadioChange}
                    />
                    <input
                      type="radio"
                      name="select"
                      id="option-2"
                      value="Upload File"
                      checked={selectedOption === "Upload File"}
                      onChange={handleRadioChange}
                    />
                    <label for="option-1" className="option option-1">
                      <div className="dot"></div>
                      <span>Enter the Text manually</span>
                    </label>
                    <label for="option-2" className="option option-2">
                      <div className="dot"></div>
                      <span>Upload the File</span>
                    </label>
                  </div>

                  {selectedOption === "Enter Free Text" && (
                    <div className="editorbox mt-4 mb-4">
                      <h5>Write the text in below editor</h5>
                      <div className="d-block">
                      <ReactQuill 
                      theme="snow" 
                      value={content} 
                      onChange={handleContentChange} 
                      style={{ height: '250px', marginBottom: '80px' }} 
                      placeholder="Start typing..." 
                    />
                       {/* <Editor
                          onChange={hangleEditorChange}
                          name="doc_text"
                          apiKey="xfu9g0e7vsqg39oakevhop9mmjgyuh4d7fgxfa240y73ybtw"
                          onInit={(evt, editor) => (editorRef.current = editor)}
                          initialValue={editorval}
                          init={{
                            height: 200,
                            menubar: false,
                            plugins: [
                              "advlist",
                              "autolink",
                              "lists",
                              "link",
                              "image",
                              "charmap",
                              "anchor",
                              "searchreplace",
                              "help",
                              "wordcount",
                              "image",
                            ],
                            toolbar:
                              "undo redo | bold italic underline  | " +
                              "alignleft aligncenter alignright alignjustify | " +
                              "bullist numlist | outdent indent | " +
                              "removeformat | help",
                            // plugins: [
                            //   'advlist autolink lists link image charmap print preview anchor',
                            //   'searchreplace visualblocks code fullscreen',
                            //   'insertdatetime media table paste code help wordcount'
                            // ],
                            // toolbar: 'undo redo | formatselect | ' +
                            // 'bold italic backcolor | alignleft aligncenter ' +
                            // 'alignright alignjustify | bullist numlist outdent indent | ' +
                            // 'removeformat | help',
                            content_style:
                              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                          }}
                        /> */}
                      </div>
                    </div>
                  )}

                  {selectedOption === "Upload File" && (
                    <div className="formatmain-box mt-3">
                      <h5 className="mb-2">
                        Upload a document to get an instant quote
                      </h5>
                      <div className="drop-box">
                        <Dropzone onDrop={onDrop}>
                          {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()}>
                              <input {...getInputProps()} />
                              <img src="assets/images/upload.svg" alt="" />
                              <p>Select a file or drag it here</p>
                              <div className="btn btn-outline-primary">
                                Select a File
                              </div>
                            </div>
                          )}
                        </Dropzone>
                      </div>
                      <p className="dropbox-info">
                        Accepted file formats: PDF, DOC, DOCX ,PPT
                      </p>
                      
                      {isFile.length > 0 && (
                        <ul>
                          {isFile.map((files, index) => (
                            <li className="text-green" key={index}> {files.name} &nbsp; <a className="text-red" 
                            onClick={(e) => {
                              e.preventDefault(); // Prevent default action
                              handleRemoveFile(index);
                            }}
                            href="#">Remove</a></li>
                          ))}
                        </ul>
                      )} 
                    </div>
                  )}
                </div>

                {/* <div className="formatmain-box">
            <div className="title_box">
            <img src={process.env.PUBLIC_URL + '/assets/images/format.svg'} alt="Format" /> <h3>Format</h3>
            </div>
                
                <div className="format-selction-box">
                    <div className="format-info">
                        <label>
                        <input type="radio" name="docType" value="1" onClick={(e) => cookies.set('docType',e.target.value, { path: '/' })}/>
                        <img src={process.env.PUBLIC_URL + '/assets/images/doc-icon.svg'} alt="Doc Selection" />
                        <h5>MS Word</h5> </label>
                        
                    </div>

                    <div className="format-info">
                        <label>
                        <input type="radio" name="docType" value="2" onClick={(e) => cookies.set('docType',e.target.value, { path: '/' })} />
                        <img src={process.env.PUBLIC_URL + '/assets/images/ppt-icon.svg'} alt="Option 1" />
                        <h5>MS Powerpoint</h5> </label>
                    
                    </div>

                    <div className="format-info">
                        <label>
                        <input type="radio" name="docType" value="3" onClick={(e) => cookies.set('docType',e.target.value, { path: '/' })} />
                        <img src={process.env.PUBLIC_URL + '/assets/images/pdf-icon.svg'} alt="Option 1" />
                        <h5>PDF</h5>  </label>
                        
                    </div>

                    <div className="format-info">
                        <label>
                        <input type="radio" name="docType" value="4" onClick={(e) => cookies.set('docType',e.target.value, { path: '/' })} />
                        <img src={process.env.PUBLIC_URL + '/assets/images/txt.png'} alt="Option 1" />
                        <h5>Text File</h5> </label>
                        
                    </div>
                </div>
            </div>     */}
                {selectedOption !== "Enter Free Text" && (
                  <div className="formatmain-box">
                    <div className="title_box">
                      <img
                        src={
                          process.env.PUBLIC_URL + "/assets/images/length.svg"
                        }
                        alt="Format"
                      />
                      <h3>Length</h3>
                    </div>

                    <div className="format-selction-box">
                      <div className="format-info">
                        <input
                          type="range"
                          name="wordCount"
                          min="250"
                          max="25000"
                          value={wordCount}
                          onChange={handleChangeRange}
                        />
                        <p>Words: {wordCount}</p>
                      </div>
                    </div>
                  </div>
                )}
                <div className="formatmain-box">
                  <div className="title_box">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/images/service.svg"
                      }
                      alt="Format"
                    />
                    <h3>Services</h3>
                  </div>

                  <div className="format-info text-left card-style3">
                    <div className="content">
                      {planList.map((item, i) => (
                        <>
                          <label key={i} className="box">
                            <input
                              type="radio"
                              name="jobType"
                              value={item.id}
                              checked={
                                selectedJobType == item.id ||
                                selectedJobType == item.id
                              }
                              onChange={onJobChanged}
                            />
                            <span className="option">
                              <i className="fa fa-check-circle"></i>
                              <div className="icon-box service_content">
                                <h5>{item.title}</h5>
                                {/* <p>+  ${item.price}</p> */}
                                <p>${item.id === 1 ? price : superPrice}</p>
                                <div className="">
                                  {ReactHtmlParser(item.description)}
                                </div>
                              </div>
                            </span>
                          </label>
                        </>
                      ))}
                    </div>
                    {/* <ul className="payment-methods service_list">
                        <li className="payment-method">
                            <input name="service" type="radio" id="Proofreading" />
                            <label htmlFor="Proofreading">
                            <h4>Proofreading</h4>
                                    <p>+ $5000.00</p>
                                        <div className="service_content">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</div>    </label>
                        </li>

                        <li className="payment-method">
                            <input name="service" type="radio" id="Editing" />
                            <label htmlFor="Editing">
                                    <h4>Editing</h4>
                                    <p>+ $3000.00</p>
                                        <div className="service_content">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</div>    
                                    </label>
                        </li>

                        <li className="payment-method">
                            <input name="service" type="radio" id="Formatting" />
                            <label htmlFor="Formatting">
                                    <h4>Formatting</h4>
                                    <p>+ $2000.00</p>
                                        <div className="service_content">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</div>    
                                    </label>
                        </li>
                        </ul> */}

                    {/* <fieldset className="checkbox-group">
                        
                            <div className="checkbox">
                                <label className="checkbox-wrapper">
                                    <input type="checkbox" className="checkbox-input" />
                                    <span className="checkbox-tile">
                                    <span className="checkbox-label service_title">Proofreading</span>
                                        <span className="checkbox-icon service_pricing">
                                            + $5000.00    
                                        </span>  
                                        
                                    </span>
                                    
                                </label>
                            </div>
                            <div className="checkbox">
                                <label className="checkbox-wrapper">
                                    <input type="checkbox" className="checkbox-input" />
                                    <span className="checkbox-tile">
                                        <span className="checkbox-label service_title">Editing</span> 
                                        <span className="checkbox-icon service_pricing">
                                            + $3000.00  
                                        </span>
                                        <span className="service_content">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</span>
                                    </span>
                                    
                                </label>
                            </div>
                            <div className="checkbox">
                                <label className="checkbox-wrapper">
                                    <input type="checkbox" className="checkbox-input" />
                                    <span className="checkbox-tile">
                                        <span className="checkbox-label service_title">Formatting</span>
                                        <span className="checkbox-icon service_pricing">
                                            + $2000.00  
                                        </span>
                                        <span className="service_content">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</span>    
                                    </span>
                                    
                                </label>
                            </div>
                        </fieldset> */}
                  </div>
                </div>

                <div className="clearfix"></div>

                <div className="formatmain-box">
                  <div className="title_box">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/images/delivery.svg"
                      }
                      alt="Format"
                    />{" "}
                    <h3>Delivery</h3>
                  </div>

                  <div className="format-info text-left">
                    <ul className="payment-methods">
                      <li className="payment-method paypal">
                        <input
                          name="delivery"
                          type="hidden"
                          value={deliverydate}
                        />
                        <input
                          name="deliverytype"
                          type="radio"
                          id="21may"
                          value="1"
                          checked={selectedDelType === "Regular"}
                          onChange={onDeleveryTypeChange}
                        />
                        <label htmlFor="21may">
                          <span className="d-block mb-2">
                            <i className="fa-solid fa-paper-plane"></i>
                          </span>
                          {delRegular}
                        </label>
                      </li>
                      <li className="payment-method pagseguro">
                        <input
                          name="delivery"
                          type="hidden"
                          value="2023-07-25 15:25"
                        />
                        <input
                          name="deliverytype"
                          type="radio"
                          id="june"
                          value="2"
                          checked={selectedDelType === "Fast"}
                          onChange={onDeleveryTypeChange}
                        />
                        <label htmlFor="june">
                          <span className="d-block mb-2">
                            <i className="fa-solid fa-plane"></i>
                          </span>
                          {delFast}
                        </label>
                      </li>
                      <li className="payment-method bankslip">
                        <input
                          name="delivery"
                          type="hidden"
                          value="2023-07-30 11:30"
                        />
                        <input
                          name="deliverytype"
                          type="radio"
                          id="10june"
                          value="3"
                          checked={selectedDelType === "SuperFast"}
                          onChange={onDeleveryTypeChange}
                        />
                        <label htmlFor="10june">
                          <span className="d-block mb-2">
                            <i className="fa-solid fa-rocket"></i>
                          </span>
                          {delSuperFast}
                        </label>
                      </li>
                    </ul>
                    {/* <ul className="payment-methods">
                        <li className="payment-method paypal">
                          <input
                            name="delivery"
                            type="hidden"
                            value={deliverydate}
                          />
                          <input
                            name="deliverytype"
                            type="radio"
                            id="regular"
                            value="Regular Delivery"
                            onChange={onDeleveryTypeChange}
                          />
                          <label htmlFor="regular">
                            <span className="d-block mb-2">
                              <i className="fa-solid fa-paper-plane"></i>
                            </span>
                            Regular Delivery -
                            {calculateDeliveryDate("Regular Delivery")}
                          </label>
                        </li>

                        <li className="payment-method pagseguro">
                          <input
                            name="delivery"
                            type="hidden"
                            value={deliverydate}
                          />
                          <input
                            name="deliverytype"
                            type="radio"
                            id="fast"
                            value="Fast Delivery"
                            onChange={onDeleveryTypeChange}
                          />
                          <label htmlFor="fast">
                            <span className="d-block mb-2">
                              <i className="fa-solid fa-plane"></i>
                            </span>
                            Fast Delivery -
                            {calculateDeliveryDate("Fast Delivery")}
                          </label>
                        </li>

                        <li className="payment-method bankslip">
                          <input
                            name="delivery"
                            type="hidden"
                            value={deliverydate}
                          />
                          <input
                            name="deliverytype"
                            type="radio"
                            id="superfast"
                            value="Super Fast Delivery"
                            onChange={onDeleveryTypeChange}
                          />

                          <label htmlFor="superfast">
                            <span className="d-block mb-2">
                              <i className="fa-solid fa-rocket"></i>
                            </span>
                            Super Fast Delivery -
                            {calculateDeliveryDate("Super Fast Delivery")}
                          </label>
                        </li>
                      </ul> */}

                    {/* <fieldset className="checkbox-group justify-content-start">
                        
                            <div className="checkbox">
                                <label className="checkbox-wrapper">
                                    <input type="checkbox" className="checkbox-input" />
                                    <span className="checkbox-tile align-items-center">
                                        
                                        <span className="checkbox-label"></span>
                                    </span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label className="checkbox-wrapper">
                                    <input type="checkbox" className="checkbox-input" />
                                    <span className="checkbox-tile align-items-center">
                                        <span className="checkbox-icon">
                                        
                                        </span>
                                        <span className="checkbox-label">15th May 02:30 am</span>
                                    </span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label className="checkbox-wrapper">
                                    <input type="checkbox" className="checkbox-input" />
                                    <span className="checkbox-tile align-items-center">
                                        <span className="checkbox-icon">
                                            
                                        </span>
                                        <span className="checkbox-label">10th May 09:30 pm</span>
                                    </span>
                                </label>
                            </div>
                        </fieldset> */}

                    {/* <div className="delivery-selection">
                        <input type="radio" name="delivery" id="normal" value="normal" />
                            <label htmlFor="normal">
                                
                                
                                <span>21st May 11:30 am</span>
                            </label>
                            <input type="radio" name="delivery" id="express" value="express"/>
                            <label htmlFor="express">
                                    
                                <span>5th May 02:30 am</span>
                            </label>

                            <input type="radio" name="delivery" id="rapid" value="rapid"/>
                            <label htmlFor="rapid">
                            
                                <span>29th April 09:30 pm</span>
                            </label>
                        </div> */}
                  </div>
                </div>
              </div>
              <div className="col-5 col-sm-4">
                <div className="calculation_box sticky-top p-3 bg-light shadow mt-lg-4">
                  <h2 className="text-center mb-4">Your price</h2>
                  <div className="d-flex justify-content-between mb-1 small">
                    <span>{jobType}</span> <span>${jobPrice}</span>
                  </div>

                  <div className="d-flex justify-content-between mb-1 small">
                    <span>{deliveryType}</span>{" "}
                    <span className="text-danger">${deliveryCharge}</span>
                  </div>
                  <div className="d-flex justify-content-between mb-1 small">
                    <span>Tax</span>{" "}
                    <span className="text-danger">${setting.tax_amount}</span>
                  </div>

                  <hr />
                  <div className="d-flex justify-content-between mb-4 small">
                    <span>TOTAL</span>{" "}
                    <strong className="text-dark">
                      $
                      {parseFloat(jobPrice) +
                        parseFloat(deliveryCharge) +
                        parseFloat(setting.tax_amount)}{" "}
                    </strong>
                  </div>

                  <div className="btn-box mt-3 text-right">
                    <button
                      type="button"
                      className="btn btn-success submit-btn"
                      id="go-checkout"
                      onClick={handleShowCheckout}
                    >
                      Submit Document
                    </button>

                    {/* <Link to="/" className="btn btn-success">Upload Now</Link> */}
                  </div>
                  {/* <div className="pricing-table">
                    <table className="table custom-pricing-table">
                        <tbody>
                            <tr><td>Proofreading</td><td>$5000.00</td></tr>
                            <tr><td>Editing</td><td>$2500.00</td></tr>
                            <tr><td>Regular delivery</td><td>$0.00</td></tr>
                        </tbody>
                    </table>
                </div> */}

                  <div className="support-box mt-5 mb-4">
                    <div className="text-center">
                      <h3>Have a large project or need ongoing support?</h3>
                      <Link to="tel:+61 435 041 034" className="btn btn-outline-primary mt-4">
                        Schedule A Call Today
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      {/*<form onSubmit={handleSubmit}>*/}
        {isCheckout === 1 && (
          <section className="section-padding pt-0" id="business">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-10 col-sm-12 col-12">
                  <div className="card shadow business-tab-content">
                    <div className="row">
                      <div className="col-md-6 col-sm-12 col-12">
                        <h4 className="package_selection">
                          Package Selected{" "}
                          <span className="d-block mt-2 mb-4">{jobType}</span>
                        </h4>
                        <div className="deadline_time">
                          <h5>
                            Deadline : <span>{deliverydate}</span>
                          </h5>
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12 col-12">
                        <table className="table table-bordered payment-table">
                          <tbody>
                            <tr>
                              <td>{jobType}</td>
                              <td className="text-right">${jobPrice}</td>
                            </tr>
                            <tr>
                              <td>{deliveryType}</td>
                              <td className="text-right">${deliveryCharge}</td>
                            </tr>
                            <tr>
                              <td>Tax</td>
                              <td className="text-right">
                                ${setting.tax_amount}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b>Total Amount</b>
                              </td>
                              <td className="text-right">
                                <b>
                                  $
                                  {parseInt(jobPrice) +
                                    parseInt(deliveryCharge) +
                                    parseInt(setting.tax_amount)}
                                </b>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="title_box">
                      <img
                        src={
                          process.env.PUBLIC_URL + "/assets/images/service.svg"
                        }
                        alt="Format"
                      />{" "}
                      <h3>Name/Email</h3>
                    </div>

                    <div className="row">
                      <div className="col-md-6 col-sm-12 col-12">
                        <div className="form-group">
                          <label>Full Name</label>

                          <input
                            type="text"
                            className="form-control"
                            
                            name="name"
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12 col-12">
                        <div className="form-group">
                          <label>Email Address</label>
                          <input type="hidden" name="timezone" id="timezone" />
                          <input
                            type="text"
                           
                            name="email"
                            onChange={handleChange}
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                    <br></br>
                    <div className="row">
                      <div className="col-md-12 col-sm-12 col-12">
                        <div className="form-group">
                          <div className="accordion" id="accordionExample">
                            
                          <div className="card">
                
                        <Elements stripe={stripePromise} options={{clientSecret}}>
                          <CheckoutFormPricing props={props}/>
                      </Elements>
                  <div className="card-header p-0 d-none">
                    <div className="mb-0">
                      <a
                        herf="#"
                        className="btn btn-light btn-block text-left p-3 rounded-0"
                        data-toggle="collapse"
                        data-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        <div className="d-flex align-items-center justify-content-between">
                          <span>Credit card</span>
                          <div className="icons">
                            <img
                              src="https://i.imgur.com/2ISgYja.png"
                              alt=" "
                              width="30"
                            />
                            <img
                              src="https://i.imgur.com/W1vtnOV.png"
                              alt=" "
                              width="30"
                            />
                            <img
                              src="https://i.imgur.com/35tC99g.png"
                              alt=" "
                              width="30"
                            />
                            <img
                              src="https://i.imgur.com/2ISgYja.png"
                              alt=" "
                              width="30"
                            />
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                 
                  
                  <div
                    id="collapseOne"
                    className="collapse show d-none"
                    aria-labelledby="headingOne"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body payment-card-body">
                      <span className="font-weight-normal card-text">
                        Card Number
                      </span>
                      <div className="input">
                        <i className="fa fa-credit-card"></i>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="0000 0000 0000 0000"
                        />
                      </div>

                      <div className="row mt-3 mb-3">
                        <div className="col-md-6">
                          <span className="font-weight-normal card-text">
                            Expiry Date
                          </span>
                          <div className="input">
                            <i className="fa fa-calendar"></i>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="MM/YY"
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <span className="font-weight-normal card-text">
                            CVC/CVV
                          </span>
                          <div className="input">
                            <i className="fa fa-lock"></i>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="000"
                            />
                          </div>
                        </div>
                      </div>

                      <span className="text-muted certificate-text">
                        <i className="fa fa-lock"></i> Your transaction is
                        secured with ssl certificate
                      </span>
                    </div>
                  </div>
                </div>
                            
                           
                           
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6 text-left">
                            <button
                              className="btn btn-outline-primary ml-3"
                              onClick={goBack}
                            >
                              Back
                            </button>
                          </div>
                          
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
  {/*    </form>  */}
    </>
  );
}

export default PricingPage;
