import React from "react";

function TopNotificationBar() {
    return(
        <section className="notice_bar">
            <div className="container">
            <div className="row">
                <div className="col-12 text-center">Professional editing and proofreading services at your fingertips</div>
            </div>
            </div>
        </section>
    );

}

export default TopNotificationBar;