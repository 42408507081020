import React from "react";
import { Link } from "react-router-dom";

function FooterSection() {
     return(
        <section className="footer-section">
             <div className="container">
                 <div className="row">
                    <div className="col-lg-2 col-md-6 col-sm-12 col-6">
                        <div className="widget_title">Popular Services</div>
                        <ul className="footer-menu">
                       <li><Link to="#">Dissertation Editing</Link></li> 
                       <li><Link to="#">Proofreading</Link></li> 
                       <li><Link to="#">Research Papers</Link></li> 
                       <li><Link to="#">Thesis Editing</Link></li> 
                       <li><Link to="#">Book Editing</Link></li> 
                       <li><Link to="#">Adult Fiction Editing</Link></li> 
                        </ul>
                    </div>

                    <div className="col-lg-2 col-md-6 col-sm-12 col-6">
                        <div className="widget_title">Assurances</div>
                        <ul className="footer-menu">
                       <li><Link to="/terms-and-conditions">Terms & Conditions</Link></li> 
                       <li><Link to="/security-information">Security Information</Link></li> 
                       <li><Link to="/privacy-policy">Privacy Policy</Link></li> 
                       <li><Link to="/quality-assurance">Quality Assurance</Link></li> 
                       <li><Link to="/get-free-sample">Free Sample</Link></li> 
                       <li><Link to="#">Adult Fiction Editing </Link></li> 
                        </ul>
                    </div>

                    <div className="col-lg-2 col-md-6 col-sm-12 col-6">
                        <div className="widget_title">Quick Links</div>
                        <ul className="footer-menu">
                       <li><Link to="/blog">Blog</Link></li> 
                       <li><Link to="/about">About Us</Link></li> 
                       <li><Link to="/reviews">Reviews</Link></li> 
                       <li><Link to="/Jobs">Jobs</Link></li> 
                       <li><Link to="/how-we-work">How We Work</Link></li> 
                       <li><Link to="#">Site Map</Link></li> 
                        </ul>
                    </div>

                    <div className="col-lg-2 col-md-6 col-sm-12 col-6">
                      <div className="widget_title">Happy Customer</div>  
                        <ul className="footer-menu">
                        <li><Link to="#">Customer Reviews</Link></li> 
                       <li><Link to="#">Case Studies</Link></li> 
                        </ul> 


                    </div>

                    {/* <div className="col-lg-2 col-md-6 col-sm-12 col-6">
                        <div className="widget_title">Quick Links</div>
                        <ul className="footer-menu">
                        <li><Link to="/team">Our Team</Link></li> 
                       <li><Link to="#">About Us</Link></li> 
                       <li><Link to="/reviews">Reviews</Link></li> 
                       <li><Link to="/jobs">Jobs</Link></li> 
                       <li><Link to="/how-we-work">How We Work</Link></li> 
                       <li><Link to="#">Site Map</Link></li> 
                        </ul>
                    </div> */}

                    

                    <div className="col-lg-4 col-md-6 col-sm-12 col-6">
                        <div className="widget_title">Support</div>
                        <ul className="contact-details">
                        <li><Link to="tel:+61435041034"><i className="fa-solid fa-phone"></i>+61 435 041 034</Link> </li>
                       <li><Link to="#"><i className="fa-solid fa-location-dot"></i>905 High Street Road Glen Waverley VIC  3150 Australia</Link></li> 
                       <li><i className="fa-solid fa-clock"></i>9am and 10pm AEST</li> 
                        
                       <li><i className="fa-solid fa-globe"></i>ABN: 27 622 203 374</li> 
                        
                        </ul>
                    </div>

                    </div>
                    <div className="row mt-4">

                    <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                        <div className="widget_title mt-4">Technologies</div>  
                        <ul className="footer-menu">
                        <li><Link to="#">Artificial Intelligence</Link></li> 
                        <li><Link to="#">Editing & Proofreading API</Link></li>
                         <li><Link to="#">NLP API COMING SOON</Link></li> 
                        </ul> 

                    </div>

                    <div className="col-lg-2 col-md-6 col-sm-12 col-12">
                        <div className="widget_title">Academic</div>  
                        <ul className="footer-menu">
                        <li><Link to="#">Academic Editing</Link></li> 
                       <li><Link to="#">Academic Proofreading</Link></li> 
                       <li><Link to="#">Dissertation Editing</Link></li> 
                       <li><Link to="#">Dissertation Proofreading</Link></li> 
                        </ul> 
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                        <div className="widget_title">Students</div>  
                        <ul className="footer-menu">
                        <li><Link to="#">Essay Editing</Link></li> 
                        <li><Link to="#">Essay Proofreading</Link></li> 
                       <li><Link to="#">Admissions Essay Editing</Link></li> 
                       <li><Link to="#">Admissions Essay Proofreading</Link></li> 
                        </ul> 
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                        <div className="widget_title">FAQs</div>  
                        <ul className="footer-menu">
                        <li><Link to="#">Technical Issues</Link></li>
                        <li><Link to="#">Why Should You Use Wordsru?</Link></li> 
                       <li><Link to="#">General Questions About Wordsru</Link></li> 
                       <li><Link to="#">Pricing, Payments, and Currencies</Link></li> 
                       <li><Link to="#">Freelance Jobs</Link></li> 
                       <li><Link to="#">Jobs</Link></li> 
                        </ul> 
                    </div>


                 </div>
             </div>


         <div className="footer-images">
             <div className="container">
                <div className="row">
                    <div className="col-12">
                        <ul>
                            <li><img src={process.env.PUBLIC_URL + '/assets/images/footer-image-01.png'} alt=""/></li>
                            <li><img src={process.env.PUBLIC_URL + '/assets/images/footer-image-02.png'} alt=""/></li>
                            <li><img src={process.env.PUBLIC_URL + '/assets/images/footer-image-03.png'} alt=""/></li>
                            <li><img src={process.env.PUBLIC_URL + '/assets/images/footer-image-04.png'} alt=""/></li>
                            <li><img src={process.env.PUBLIC_URL + '/assets/images/payments.png'} alt=""/></li>
                        </ul>
                    </div>
                </div>
             </div>
         </div>  

         <hr/>
         <div className="copright-text">
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center">
                    © WordsRu 2023. All rights reserved.
                    </div>
                </div>
            </div>
            </div>  
        </section>
     );

}
export default FooterSection;