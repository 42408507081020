import React, {useState} from "react";
import { Link } from "react-router-dom";
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';
import { Navigation, Autoplay  } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';

import '../../assets/css/services.css';

import img1 from '../../assets/images/before.png';
import img2 from '../../assets/images/after.png';


function Proofreading(){
    const [value, onChange] = useState(new Date());
    return(
        <>

    <section className="inner-page section-themebg">
        <div className="container">
        <div className="row">
            <div className="col-12 text-center">
                <h3>Proof Reading</h3>
            </div>
        </div>
        </div>
    </section>


    <section className="section-padding">
         <div className="container">
            <div className="row">
               <div className="col-md-8 col-sm-12 col-12">
                    <h1 className="service_main_title mb-4">Professional Proofreading Services</h1>
                    <p>In business, communication is everything! WordsRU understands how vital communication is to your business, and that is why we offer quality editing services that you can trust.
WordsRU's talented editors can assist you with a variety of business documents, such as technical and scientific reports, corporate reports, press releases, media articles, and product installation/user guides.</p>
                 <div className="btn-box"> <Link to="#" className="btn btn-dark rounded-0">Try for Free</Link></div>
                </div>
               <div className="col-md-4 col-sm-12 col-12">
                <div className="comparisonimage">
                        <ReactCompareSlider
                        itemOne={<ReactCompareSliderImage src={img1} srcSet={img1} alt="Before Image" />}
                        itemTwo={<ReactCompareSliderImage src={img2} srcSet={img2} alt="After Image" />}
                        />
                </div>
              

                        {/* <ImageSlider
                            image1={img1}
                            image2={img2}
                            onSlide={() => {
                            console.log("sliding");
                            }}
                        /> */}
               </div>
            </div>

            <div className="row mt-5">
                  <div className="col-md-4">
                      <img src={process.env.PUBLIC_URL + '/assets/images/image.png'}  className="img-fluid"/>
                  </div>  
                <div className="col-md-8 col-sm-12 col-12">
                     <h2 className="mb-4">What Our Proofreading Services Include</h2>
                     <p>Our professional proofreading service includes the following:</p>
                     <ul className="feature_list">
                        <li><i className="fa-solid fa-circle-check"></i>Correcting grammar, spelling, and punctuation errors</li>
                        <li><i className="fa-solid fa-circle-check"></i>Correcting grammar, spelling, and punctuation errors</li>
                        <li><i className="fa-solid fa-circle-check"></i>Correcting grammar, spelling, and punctuation errors</li>
                        <li><i className="fa-solid fa-circle-check"></i>Correcting grammar, spelling, and punctuation errors</li>
                     </ul>
                </div>
            </div>
         </div>
    </section>

    <section className="section-padding customer-feedback">
          <div className="container">
              <div className="row">
                   <div className="col-md-7 col-sm-12 col-12">
                   <h2 className="service_sub_title mb-4"> A Dedicated Team
Of Expert Editors Working 24/7, 365 Days A Year</h2>
<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
 <div className="btn-box mt-4"><Link to="#" className="btn btn-dark rounded-0">Get a Quote</Link></div>
                   </div>

                   <div className="col-md-5 col-sm-12 col-12">
                        <ul className="teacher-list">
                                <li> <img src={process.env.PUBLIC_URL + '/assets/images/team/team-01.jpg'} alt="" /> <h4>Aimée</h4> <span><i className="fa-solid fa-circle text-primary"></i>London</span> <span><i className="fa-solid fa-circle text-success "></i>Engineering</span> </li>
                                <li> <img src={process.env.PUBLIC_URL + '/assets/images/team/team-01.jpg'} alt="" /> <h4>Brett</h4> <span><i className="fa-solid fa-circle text-primary"></i>Exeter</span> <span><i className="fa-solid fa-circle  text-success"></i>Biology, Chemistry</span> </li>
                                <li> <img src={process.env.PUBLIC_URL + '/assets/images/team/team-03.jpg'} alt="" /> <h4>Jehane</h4> <span><i className="fa-solid fa-circle text-primary"></i>London</span> <span><i className="fa-solid fa-circle text-success "></i>Art, Painting</span> </li>
                                <li> <img src={process.env.PUBLIC_URL + '/assets/images/team/team-04.jpg'} alt="" /> <h4>Jehane</h4> <span><i className="fa-solid fa-circle text-primary"></i>Brighton & Hove</span> <span><i className="fa-solid fa-circle text-success "></i>Music, Singing</span> </li>
                                <li> <img src={process.env.PUBLIC_URL + '/assets/images/team/team-05.jpg'} alt="" /> <h4>Jehane</h4> <span><i className="fa-solid fa-circle text-primary"></i>London</span> <span><i className="fa-solid fa-circle text-success "></i>Engineering</span> </li>
                                <li> <img src={process.env.PUBLIC_URL + '/assets/images/team/team-01.jpg'} alt="" /> <h4>Jehane</h4> <span><i className="fa-solid fa-circle text-primary"></i>London</span> <span><i className="fa-solid fa-circle text-success "></i>Engineering</span> </li>
                                <li> <img src={process.env.PUBLIC_URL + '/assets/images/team/team-02.jpg'} alt="" /> <h4>Jehane</h4> <span><i className="fa-solid fa-circle text-primary"></i>London</span> <span><i className="fa-solid fa-circle text-success "></i>Engineering</span> </li>
                                <li> <img src={process.env.PUBLIC_URL + '/assets/images/team/team-02.jpg'} alt="" /> <h4>Jehane</h4> <span><i className="fa-solid fa-circle text-primary"></i>London</span> <span><i className="fa-solid fa-circle text-success "></i>Engineering</span> </li>
                               
                        </ul> 


                        
                   </div>

              </div>
          </div>  
    </section>



    <section className="section-padding">
         <div className="container">
            <div className="row mb-5">
                <div className="col-12"><h2 className="service_sub_title">How We Work</h2></div>
            </div>

            <div className="row">
                <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                     <div className="work_box">
                          <div className="img-box"><img src={process.env.PUBLIC_URL + '/assets/images/delivery.svg'} alt="" /></div>
                          <h3 className="mb-4">Lightning-Fast Delivery</h3>
                          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                     </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                    <div className="work_box">
                          <div className="img-box"><img src={process.env.PUBLIC_URL + '/assets/images/price.svg'} alt="" /></div>
                          <h3 className="mb-4">Great Pricing</h3>
                          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                     </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                    <div className="work_box">
                          <div className="img-box"><img src={process.env.PUBLIC_URL + '/assets/images/format.svg'} alt="" /></div>
                          <h3 className="mb-4">All Major Formats</h3>
                          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                     </div>
                </div>

                <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                    <div className="work_box">
                          <div className="img-box"><img src={process.env.PUBLIC_URL + '/assets/images/clock.svg'} alt="" /></div>
                          <h3 className="mb-4">24-Hour Support</h3>
                          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                     </div>
                </div>

                 


            </div>
         </div>

    </section>


    <section className="section-padding bg-success">
              <div className="container">
                   <div className="row">
                      <div className="col-12 text-center">
                       <h2 className="team_title mb-4 text-white">Request Call Back</h2>
                      </div>  
                   </div>

                  <div className="row justify-content-center">
                      <div className="col-md-8 col-sm-12 col-12">
                           <div className="requestform">
                              <div className="row justify-content-center">
                                  <div className="col-md-6 col-sm-12 col-12">
                                      <div className="form-group">
                                          <label>Full Name</label>
                                          <input type="text" className="form-control"  />
                                      </div>
                                  </div>
                                  <div className="col-md-6 col-sm-12 col-12">
                                      <div className="form-group">
                                          <label>Email Address</label>
                                          <input type="email" className="form-control"  />
                                      </div>
                                  </div>

                                  <div className="col-md-6 col-sm-12 col-12">
                                      <div className="form-group">
                                          <label>Phone Number</label>
                                          <input type="text" className="form-control"  />
                                      </div>
                                  </div>
                                  
                                  <div className="col-md-6 col-sm-12 col-12">
                                      <div className="form-group">
                                          <label>Time/date for a call</label>
                                          <DateTimePicker  className="form-control" onChange={onChange} value={value} />
                                      </div>
                                  </div>
                                  <div className="col-md-12 col-sm-12 col-12">
                                      <div className="form-group">
                                          <label>Company Name</label>
                                          <input type="text" className="form-control"  />
                                      </div>
                                  </div>
                                  <div className="col-md-12 col-sm-12 col-12">
                                      <div className="form-group text-right">
                                        <Link to="/" className="btn btn-dark rounded-0">Request Call Back</Link>
                                      </div>
                                  </div>
                                  
                                  
                            </div>
                           </div>
                      </div>
                  </div>

                  
              </div>

         </section>


    <section className="trusted-partner pt-5">
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                      <div className="section_title mb-5 text-center"><h3>Trusted by thousands of leading institutions & businesses</h3>
                      </div>
                    <Swiper
                      navigation={true} modules={[Autoplay, Navigation]}
                      spaceBetween={30}
                      autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                      }}
                      breakpoints={{
                        640: {
                          slidesPerView: 2,
                          spaceBetween: 20,
                        },
                        768: {
                          slidesPerView: 3,
                          spaceBetween: 20,
                        },
                        1024: {
                          slidesPerView: 5,
                          spaceBetween: 30,
                        },
                      }}
                      loop={true}
                      className="mySwiper-brands"             
                    >
                      <SwiperSlide><div className="img-box"><img src={process.env.PUBLIC_URL + '/assets/images/philips.png'} alt=" " className="img-fluid" /></div></SwiperSlide>
                      <SwiperSlide><div className="img-box"><img src={process.env.PUBLIC_URL + '/assets/images/horvard.png'} alt=" " className="img-fluid" /></div></SwiperSlide>
                      <SwiperSlide><div className="img-box"><img src={process.env.PUBLIC_URL + '/assets/images/philips.png'}  alt=" " className="img-fluid" /></div></SwiperSlide>
                      <SwiperSlide><div className="img-box"><img src={process.env.PUBLIC_URL + '/assets/images/horvard.png'} alt=" " className="img-fluid" /></div></SwiperSlide>
                      <SwiperSlide><div className="img-box"><img src={process.env.PUBLIC_URL + '/assets/images/philips.png'}  alt=" " className="img-fluid" /></div></SwiperSlide>
                      <SwiperSlide><div className="img-box"><img src={process.env.PUBLIC_URL + '/assets/images/horvard.png'} alt=" " className="img-fluid" /></div></SwiperSlide>
                      
                    </Swiper>
                    </div>
                  </div>
                </div>
         </section>

        
        </>
    );
}

export default Proofreading;