import React from "react";
import InnerInstantQuote from "../common/InnerinstantQuote";

function OurTeam(){
    return(
      <>
      <InnerInstantQuote />
      <section className="section-padding">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-6 col-sm-12 col-12 text-center">
                        <h2 className="team_title">Meet Our Team of Expert Editors and Proofreaders</h2>
                        <div className="subdescription">Skilled language experts and academic scholars, WordsRU editors are some of 
the best in the industry. </div>
                    </div>
                </div>

                <div className="row mt-5">
                    <div className="col-md-4 col-sm-12 col-12">
                        <div className="team-10">
                            
                            <div className="team-img">
                                <img src={process.env.PUBLIC_URL + '/assets/images/team/team-01.jpg'} alt=" " />
                            </div>
                            <div className="team-content">
                            <span className="bordertop"></span>
                                <h3>Bernice</h3>
                                <h5>Chief Editor</h5>                            
                                
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 col-sm-12 col-12">
                        <div className="team-10">
                           
                            <div className="team-img">
                                <img src={process.env.PUBLIC_URL + '/assets/images/team/team-03.jpg'} alt=" " />
                            </div>
                            <div className="team-content">
                            <span className="bordertop"></span>
                                <h3>Brett</h3>
                                <h5>Editor</h5>                            
                                
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 col-sm-12 col-12">
                        <div className="team-10">
                           
                            <div className="team-img">
                                <img src={process.env.PUBLIC_URL + '/assets/images/team/team-02.jpg'} alt=" " />
                            </div>
                            <div className="team-content">
                            <span className="bordertop"></span>
                                <h3>Jehane</h3>
                                <h5>Editor</h5>                            
                                
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 col-sm-12 col-12">
                        <div className="team-10">
                           
                            <div className="team-img">
                                <img src={process.env.PUBLIC_URL + '/assets/images/team/team-02.jpg'} alt=" " />
                            </div>
                            <div className="team-content">
                            <span className="bordertop"></span>
                                <h3>Jehane</h3>
                                <h5>Editor</h5>                            
                                
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 col-sm-12 col-12">
                        <div className="team-10">
                           
                            <div className="team-img">
                                <img src={process.env.PUBLIC_URL + '/assets/images/team/team-02.jpg'} alt=" " />
                            </div>
                            <div className="team-content">
                            <span className="bordertop"></span>
                                <h3>Jehane</h3>
                                <h5>Editor</h5>                            
                                
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 col-sm-12 col-12">
                        <div className="team-10">
                           
                            <div className="team-img">
                                <img src={process.env.PUBLIC_URL + '/assets/images/team/team-02.jpg'} alt=" " />
                            </div>
                            <div className="team-content">
                            <span className="bordertop"></span>
                                <h3>Jehane</h3>
                                <h5>Editor</h5>                            
                                
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 col-sm-12 col-12">
                        <div className="team-10">
                           
                            <div className="team-img">
                                <img src={process.env.PUBLIC_URL + '/assets/images/team/team-02.jpg'} alt=" " />
                            </div>
                            <div className="team-content">
                            <span className="bordertop"></span>
                                <h3>Jehane</h3>
                                <h5>Editor</h5>                            
                                
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12 col-12">
                        <div className="team-10">
                           
                            <div className="team-img">
                                <img src={process.env.PUBLIC_URL + '/assets/images/team/team-02.jpg'} alt=" " />
                            </div>
                            <div className="team-content">
                            <span className="bordertop"></span>
                                <h3>Jehane</h3>
                                <h5>Editor</h5>                            
                                
                            </div>
                        </div>
                    </div>

                </div>
            </div>
      </section>
      </>  
    );
}

export default OurTeam;