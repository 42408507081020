import React from "react";
import { Link } from "react-router-dom";
import "../assets/instantquote.css";

function InnerInstantQuote(){
    return(
        <>
        <section className="section-themebg innerinstantquote">
             <div className="container">
             <div className="row">
                    <div className="col-12">
                        <h1>Instant Quote</h1>                        
                    </div>
                </div>
                <div className="row">
                                     <div className="col-md-3 col-sm-6 col-12"> <div className="form-group">
                                          <label>Service Type</label>
                                          <select className="form-control">
                                            <option selected>Editing</option>
                                            <option>ProofReading</option>
                                            <option>Formating</option>
                                            <option>Editing</option>
                                          </select>
                                      </div>
                                     </div>
                                     <div className="col-md-3 col-sm-6 col-12"> 
                                     
                                     <div className="form-group">
                                          <label>Delivery Date</label>
                                          <input type="date" className="form-control"/>
                                      </div>
                                     </div>

                                     <div className="col-md-3 col-sm-6 col-12"> 
                                        <div className="form-group">
                                          <label>Length</label>
                                          <input type="text" className="form-control" placeholder="1100 words"/>
                                         </div>
                                     </div>
                                     <div className="col-md-3 col-sm-6 col-12">                                      
                                          <div className="btn-main-box">
                                                <div className="form-group">
                                                  <label>Price</label>
                                                  <span className="d-block">$26.00</span>
                                                </div>

                                                <div className="btn-box text-center">
                                                  <button type="submit" className="btn btn-dark get-quote">Get a Quote</button>
                                                  <Link to="/" className="d-block free-sample">Get your free Sample</Link>
                                                </div>
                                          </div>
                                     </div>

                                </div>
             </div>
        </section>
        </>
    );

}

export default InnerInstantQuote;