import React from "react";

function FAQEnglish(){
    return(
        <>
          <section className="inner-page section-themebg">
              <div className="container">
                <div className="row">
                    <div className="col-12 text-center">
                        <h3>FAQ English as a Second Language</h3>
                    </div>
                </div>
              </div>
         </section>

         <section className="section-padding inner-content">
              <div className="container">
              <div className="row justify-content-center">
                    <div className="col-md-10 col-sm-12 col-12">
                    <div className="accordion-section clearfix mt-3" aria-label="Question Accordions">
				  <div className="container">
				  
					  <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">

                          <div className="panel panel-default mb-3">
                              <div className="panel-heading p-3 " role="tab" id="heading0">
                                  <h3 className="panel-title">
                                      <a className="collapsed" role="button" title="" data-toggle="collapse" data-parent="#accordion" href="#collapse0" aria-expanded="true" aria-controls="collapse0">
                                           What kind of academic work do you edit?
                                      </a>
                                  </h3>
                              </div>
                              <div id="collapse0" className="panel-collapse collapse" role="tabpanel" aria-labelledby="heading0">
                                  <div className="panel-body mb-4">
                                      <p>The time required for an edit depends on the length and condition of the project, but we will make every effort to accommodate your deadline. WordsRU editors can usually return documents of 2000 words or less in 24 hours (upon receipt of payment), we also offer expedited service if you need an edit done more quickly.</p>

                                  </div>
                              </div>
                          </div>

                          <div className="panel panel-default  mb-3">
                              <div className="panel-heading p-3" role="tab" id="heading1">
                                  <h3 className="panel-title">
                                      <a className="collapsed" role="button" title="" data-toggle="collapse" data-parent="#accordion" href="#collapse1" aria-expanded="true" aria-controls="collapse1">
                                      What kinds of errors will you fix?
                                      </a>
                                  </h3>
                              </div>
                              <div id="collapse1" className="panel-collapse collapse" role="tabpanel" aria-labelledby="heading1">
                                  <div className="panel-body mb-4">
                                  <p>The time required for an edit depends on the length and condition of the project, but we will make every effort to accommodate your deadline. WordsRU editors can usually return documents of 2000 words or less in 24 hours (upon receipt of payment), we also offer expedited service if you need an edit done more quickly.</p>

                                  </div>
                              </div>
                          </div>


                          <div className="panel panel-default  mb-3">
                              <div className="panel-heading p-3 " role="tab" id="heading2">
                                  <h3 className="panel-title">
                                      <a className="collapsed" role="button" title="" data-toggle="collapse" data-parent="#accordion" href="#collapse2" aria-expanded="true" aria-controls="collapse2">
                                      How quickly can you edit my paper?
                                      </a>
                                  </h3>
                              </div>
                              <div id="collapse2" className="panel-collapse collapse" role="tabpanel" aria-labelledby="heading2">
                                  <div className="panel-body mb-4">
                                  <p>The time required for an edit depends on the length and condition of the project, but we will make every effort to accommodate your deadline. WordsRU editors can usually return documents of 2000 words or less in 24 hours (upon receipt of payment), we also offer expedited service if you need an edit done more quickly.</p>
                                  <p>Please remember that many academic documents benefit from consultation between the editor and the author. This is especially so for documents written by less-experienced students or by students and academics who have difficulty expressing themselves in writing (such as those for whom English is a second language). The consultation process can add great value to your project, but it also takes time. You should also allow yourself at least a day to review the edited paper and make any revisions.</p>
                                  </div>
                              </div>
                          </div>


                            <div className="panel panel-default  mb-3">
                              <div className="panel-heading p-3" role="tab" id="heading3">
                                  <h3 className="panel-title">
                                      <a className="collapsed" role="button" title="" data-toggle="collapse" data-parent="#accordion" href="#collapse3" aria-expanded="true" aria-controls="collapse3">
                                         How much will it cost me?
                                      </a>
                                  </h3>
                              </div>
                              <div id="collapse3" className="panel-collapse collapse" role="tabpanel" aria-labelledby="heading3">
                                  <div className="panel-body mb-4">
                                  <p>The time required for an edit depends on the length and condition of the project, but we will make every effort to accommodate your deadline. WordsRU editors can usually return documents of 2000 words or less in 24 hours (upon receipt of payment), we also offer expedited service if you need an edit done more quickly.</p>

                                  </div>
                              </div>
                          </div>        
					  
					                 </div>
				  
				                 </div>
		               		</div>
                    </div>
                </div>
              </div>

         </section>

        </>
    );
}
export default FAQEnglish;