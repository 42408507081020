import React, { useState, useRef, useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import { axiosRequest } from "../../api";
import { baseURL } from "../../constant";
import Cookies from "universal-cookie";

function Thanks() {
  const cookies = new Cookies();

  const [loading, setLoading] = useState(false);
  const [order, setOrderDetails] = useState("");
  const urlSearchString = window.location.search;
  const userEmail = cookies.get("userEmail");

  const params = new URLSearchParams(urlSearchString);
 
console.log(params.get('payment_intent'))

  useEffect(() => {
    const loadOrderDetails = async () => {
      // Till the data is fetch using API
      // the Loading page will show.
      setLoading(true);
      // Await make wait until that
      // promise settles and return its result
      const response = await axiosRequest.get(`${baseURL}/getOrderDetails/${params.get('payment_intent')}`);
      
      // After fetching data stored it in posts state.
      setOrderDetails(response.data);
      //
      // Closed the loading page
      setLoading(false);
    };
    loadOrderDetails();



  }, [1]);


  return (
    <>
      <section className="inner-page section-themebg">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h3>Thank You</h3>
            </div>
          </div>
        </div>
      </section>

      <section className="page_not_found">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-10 col-sm-12 col-12">
                        <div className="notfound p-5">
                            <div className="img-box text-center mb-4"><img src={process.env.PUBLIC_URL + '/assets/images/proofreading.png'} alt=""/></div>
                        <h4 className="mb-4">Your order has been successfully submitted.</h4>
                        <a className="btn btn-primary" href="#" onClick={() =>
                    window.location.replace(
                      `https://staging.wordsru.com/backend/login?email=${userEmail}&login=1`
                    )
                  }>Back to My Account</a>
                       

                        <div className="payment-summary text-left mt-5">
                            
                            <h2 className="payment-title">Order Summary</h2>
                            
                            <div className="order-summary mb-4">
                            <p className="order-id"><b>Order ID:</b>  {order.id}</p>
                            <p className="order-id"><b>Payment ID:</b>  {order.paymentId}</p>
                            </div>
                            <div className="order-summary">
                                <div className="row">
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-6">
                                        <p className="sm-text-large font-weight-bold">Order Status</p> 
                                        <p className="sm-text-large font-weight-bold">Amount</p>
                                        <p className="sm-text-large font-weight-bold">Currency</p>
                                        <p className="sm-text-large font-weight-bold">Order Date</p>
                                       
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-6 text-right">
                                        <p className="sm-text-large badge badge-success mb-2">Submitted</p>
                                        <p className="sm-text-large">${order.amountQuoted}</p>
                                        <p className="sm-text-large">{order.currency}</p>
                                        <p className="order-total sm-text-large">{order.created_date}</p>
                                    </div>
                                </div>
                                
                            </div>
                           
                            </div>



                        </div>
                    </div>
                </div>
            </div>
            </section> 
    </>
  );
}

export default Thanks;
