import React, {useState} from "react";
 
import { Navigation, Autoplay  } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import { Link } from "react-router-dom";

function BusinessPage(){
  const [value, onChange] = useState(new Date());
    return(
        <>
        <section className="inner-page section-themebg">
              <div className="container">
                <div className="row">
                    <div className="col-12 text-center">
                        <h3>For Business</h3>
                    </div>
                </div>
              </div>
        </section>

        <section className="section-padding">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-md-9 col-sm-12 col-12 text-center">
                        <h2 className="team_title">Proofreading and Editing Services for Business</h2>
                        <div className="subdescription">
                            <p>In business, communication is everything! WordsRU understands how vital communication is to your business, and that is why we offer quality editing services that you can trust.
WordsRU's talented editors can assist you with a variety of business documents, such as technical and scientific reports, corporate reports, press releases, media articles, and product installation/user guides.</p>
                          </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mt-4">
                          <div className="partner-box">
                          <Swiper
                      navigation={false} modules={[Autoplay, Navigation]}
                      spaceBetween={30}
                      autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                      }}
                      breakpoints={{
                        640: {
                          slidesPerView: 2,
                          spaceBetween: 20,
                        },
                        768: {
                          slidesPerView: 3,
                          spaceBetween: 20,
                        },
                        1024: {
                          slidesPerView: 5,
                          spaceBetween: 30,
                        },
                      }}
                      loop={true}
                      className="mySwiper-brands"             
                    >
                      <SwiperSlide><div className="img-box"><img src={process.env.PUBLIC_URL + '/assets/images/philips.png'} alt=" " className="img-fluid" /></div></SwiperSlide>
                      <SwiperSlide><div className="img-box"><img src={process.env.PUBLIC_URL + '/assets/images/horvard.png'} alt=" " className="img-fluid" /></div></SwiperSlide>
                      <SwiperSlide><div className="img-box"><img src={process.env.PUBLIC_URL + '/assets/images/philips.png'}  alt=" " className="img-fluid" /></div></SwiperSlide>
                      <SwiperSlide><div className="img-box"><img src={process.env.PUBLIC_URL + '/assets/images/horvard.png'} alt=" " className="img-fluid" /></div></SwiperSlide>
                      <SwiperSlide><div className="img-box"><img src={process.env.PUBLIC_URL + '/assets/images/philips.png'}  alt=" " className="img-fluid" /></div></SwiperSlide>
                      <SwiperSlide><div className="img-box"><img src={process.env.PUBLIC_URL + '/assets/images/horvard.png'} alt=" " className="img-fluid" /></div></SwiperSlide>
                      
                    </Swiper>
                            </div>
                    </div>
                </div>
            </div>            
        </section>


        <section className="section-padding customer-feedback">
               <div className="container">
                 
                <div className="row">      
                   
                  <div className="col-12 text-center">
                  <Swiper
          modules={[Autoplay, Navigation]}
          navigation={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          loop={true}
          spaceBetween={30}
          breakpoints={{
            640: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 1,
              spaceBetween: 30,
            },
          }}
          onSlideChange={() => console.log('slide change')}
          onSwiper={(swiper) => console.log(swiper)}
          className="mySwiper testimonial-slider"
        >
          <SwiperSlide>
          <div className="testimonial-box business-testimonial">
             
            <div className="content">“Wordsru does a great job streamlining our copy and correcting grammatical errors. Working with a team that can adhere to our writing styles has been extremely helpful.”</div>
            <h6 className="mb-0 customer">John Doe, Headmistress</h6>
            </div> 

          </SwiperSlide>
          <SwiperSlide><div className="testimonial-box  business-testimonial">
					
					
          <div className="content">“Wordsru does a great job streamlining our copy and correcting grammatical errors. Working with a team that can adhere to our writing styles has been extremely helpful.”</div>
           
          <h6 className="mb-0 customer">John Doe, Headmistress</h6>  
					</div> </SwiperSlide>
          <SwiperSlide><div className="testimonial-box  business-testimonial">
					
					
          <div className="content">“Wordsru does a great job streamlining our copy and correcting grammatical errors. Working with a team that can adhere to our writing styles has been extremely helpful.”</div>
           
          <h6 className="mb-0 customer">John Doe, Headmistress</h6>
					 
					</div> </SwiperSlide>

          <SwiperSlide><div className="testimonial-box  business-testimonial">
          <div className="content">“Wordsru does a great job streamlining our copy and correcting grammatical errors. Working with a team that can adhere to our writing styles has been extremely helpful.”</div>
           
          <h6 className="mb-0 customer">John Doe, Headmistress</h6>
						
					</div> </SwiperSlide>
           
        </Swiper>
                  </div>      

                </div>
               </div>
         </section>


         <section className="section-padding">
               <div className="container">
                <div className="row">
                    <div className="col-12 text-center">
                       <h2 className="team_title">How We Work With Businesses</h2>
                        
                    </div>
                </div>
               <div className="row mt-5">
                   <div className="col-md-6 col-sm-12 col-12 mb-lg-5 mb-4">
                        <div className="workbox">
                              <div className="img-box"><img src={process.env.PUBLIC_URL + '/assets/images/cost-effective.svg'} alt=" " className="img-fluid" /></div>
                              <h4>Cost-Effective Content</h4>
                              <div className="content">
                              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. 
                              </div>
                         </div>    
                   </div>
                   <div className="col-md-6 col-sm-12 col-12 mb-lg-5 mb-4">
                         <div className="workbox">
                              <div className="img-box"><img src={process.env.PUBLIC_URL + '/assets/images/project-manager.svg'} alt=" " className="img-fluid" /></div>
                              <h4>Dedicated Project Manager</h4>
                              <div className="content">
                              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. 
                              </div>
                         </div>   
                    </div>
                    <div className="col-md-6 col-sm-12 col-12 mb-lg-5 mb-4">
                    <div className="workbox">
                              <div className="img-box"><img src={process.env.PUBLIC_URL + '/assets/images/managed-team.svg'} alt=" " className="img-fluid" /></div>
                              <h4>Fully Managed Team</h4>
                              <div className="content">
                              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. 
                              </div>
                         </div>  
                    </div>
                    <div className="col-md-6 col-sm-12 col-12 mb-lg-5 mb-4">
                         <div className="workbox">
                              <div className="img-box"><img src={process.env.PUBLIC_URL + '/assets/images/clock.svg'} alt=" " className="img-fluid" /></div>
                              <h4>Round-The-Clock Support</h4>
                              <div className="content">
                              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. 
                              </div>
                         </div>  
                    </div>
               </div>

               </div>
         </section>

         <section className="section-padding customer-feedback">
              <div className="container">
                <div className="row mb-5">
                    <div className="col-12 text-center">
                       <h2 className="team_title">What WordsRU’s Business Editing Service Can Do for You</h2>
                        
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6 col-ms-12 col-12">
                      <div className="card p-3 h-100">
                        
                   <p><span className="dropcap">H</span>ere are just five reasons why WordsRU is the ideal solution to your editing needs:</p>

                    <div className="business-list">
                        <ul>
                            <li><i className="fa-solid fa-square-check"></i>Your corporate communications need to be perfect. After all, your communications often form a client's first impression of your company. Awkward writing, misspelled words, and errors in grammar and punctuation make your company seem slipshod, unprofessional, and uncommitted. For many customers, sloppy writing indicates sloppy business. WordsRU is your company's opportunity to put your best foot forward.</li>
                            <li><i className="fa-solid fa-square-check"></i>WordsRU's proven track record in business editing and copywriting services frees you to concentrate your resources on your core activities.</li>
                            <li><i className="fa-solid fa-square-check"></i>WordsRU provides prompt service in order to meet your deadlines.</li>
                            <li><i className="fa-solid fa-square-check"></i>You can do business at any time. WordsRU is a global, online service, open 24 hours a day, 7 days a week, everywhere in the world.</li>
                            <li><i className="fa-solid fa-square-check"></i>WordsRU takes security and privacy seriously. Our robust systems ensure that no unauthorized persons will gain access to your communications.</li>
                        </ul>
                    </div>

                    </div>  
                    </div>
                    <div className="col-md-6 col-sm-12 col-12">
                    <div className="card p-3 h-100">    
                    <p><span className="dropcap">B</span>usiness services and WordsRU go hand-in-hand. Our team of experienced editors, proofreaders, and copywriters can make your business document production professional and effective.</p>

<p><span className="dropcap">Y</span>our expertise is in what your business does - not in editing, proofreading, or writing the texts of business documents. Hiring an in-house editor/copywriter can divert resources away from your core business activities. WordsRU allows you to pay only for the work you need done and can handle any workload.</p>

<p><span className="dropcap">W</span>ordsRU's business services gives you access to our diverse, global team of talented and experienced wordsmiths. We will provide you with highly professional outcomes to give you the freedom to do what you do best.</p>
                    </div>
                   </div>    

                </div>
              </div>
         </section>

         <section className="section-padding">
              <div className="container">
                   <div className="row">
                      <div className="col-12 text-center">
                       <h2 className="team_title mb-4">Our business areas of expertise</h2>
                      </div>  
                   </div>
                   <div className="row">
                  
                        <div className="col-12">
                             {/* <div className="vertical_tab">
                             <Tabs>
                                <div className="row">
                                    <div className="col-md-3 col-sm-12 col-12">
                                        <TabList>
                                            <Tab>Business Editing </Tab>
                                            <Tab>Training Manual Editing</Tab>
                                            <Tab>Website Editing & Copy Writing</Tab>
                                            <Tab>Advertising Copy</Tab>
                                            <Tab>Presentation Editing</Tab>
                                            <Tab>Business Plan Editing</Tab>
                                            <Tab>Copywriting Services</Tab>
                                            <Tab>Business Document Editing</Tab>
                                            <Tab>Newsletter Editing</Tab>
                                            <Tab>Corporate Accounts</Tab>
                                        </TabList>
                                    </div>
                                    <div className="col-md-9 col-sm-12 col-12">
                                          
                                        <TabPanel>
                                            <p>Content for Tab 1</p>
                                        </TabPanel>
                                        <TabPanel>
                                            <p>Content for Tab 2</p>
                                        </TabPanel>
                                        <TabPanel>
                                            <p>Content for Tab 3</p>
                                        </TabPanel>
                                        <TabPanel>
                                            <p>Content for Tab 4</p>
                                        </TabPanel>
                                        <TabPanel>
                                            <p>Content for Tab 5</p>
                                        </TabPanel>
                                        <TabPanel>
                                            <p>Content for Tab 6</p>
                                        </TabPanel>
                                        <TabPanel>
                                            <p>Content for Tab 7</p>
                                        </TabPanel>
                                        <TabPanel>
                                            <p>Content for Tab 8</p>
                                        </TabPanel>

                                        <TabPanel>
                                            <p>Content for Tab 9</p>
                                        </TabPanel>
                                        <TabPanel>
                                            <p>Content for Tab 10</p>
                                        </TabPanel>
                                    </div>
                                </div>
                                

                                </Tabs>
                             </div> */}
                            <ul className="business_area">
                            <li>Business Editing </li>
                            <li> Training Manual Editing </li>
                            <li>Website Editing & Copy Writing </li>
                            <li>Advertising Copy </li>
                            <li>Presentation Editing </li>
                            <li>Business Plan Editing </li>
                            <li>Copywriting Services </li>
                            <li>Business Document Editing </li>
                            <li>Newsletter Editing </li>
                            <li>Corporate Accounts </li>
                            </ul> 
                        </div>
                   </div>
              </div>

         </section>


         <section className="section-padding bg-success">
              <div className="container">
                   <div className="row">
                      <div className="col-12 text-center">
                       <h2 className="team_title mb-4 text-white">Request Call Back</h2>
                      </div>  
                   </div>

                  <div className="row justify-content-center">
                      <div className="col-md-8 col-sm-12 col-12">
                           <div className="requestform">
                              <div className="row justify-content-center">
                                  <div className="col-md-6 col-sm-12 col-12">
                                      <div className="form-group">
                                          <label>Full Name</label>
                                          <input type="text" className="form-control"  />
                                      </div>
                                  </div>
                                  <div className="col-md-6 col-sm-12 col-12">
                                      <div className="form-group">
                                          <label>Email Address</label>
                                          <input type="email" className="form-control"  />
                                      </div>
                                  </div>

                                  <div className="col-md-6 col-sm-12 col-12">
                                      <div className="form-group">
                                          <label>Phone Number</label>
                                          <input type="text" className="form-control"  />
                                      </div>
                                  </div>
                                  
                                  <div className="col-md-6 col-sm-12 col-12">
                                      <div className="form-group">
                                          <label>Time/date for a call</label>
                                          <DateTimePicker  className="form-control" onChange={onChange} value={value} />
                                      </div>
                                  </div>
                                  <div className="col-md-12 col-sm-12 col-12">
                                      <div className="form-group">
                                          <label>Company Name</label>
                                          <input type="text" className="form-control"  />
                                      </div>
                                  </div>
                                  <div className="col-md-12 col-sm-12 col-12">
                                      <div className="form-group text-right">
                                        <Link to="/" className="btn btn-dark rounded-0">Request Call Back</Link>
                                      </div>
                                  </div>
                                  
                                  
                            </div>
                           </div>
                      </div>
                  </div>

                  
              </div>

         </section>


         <section className="section-padding customer-feedback">
            <div className="container">
                   <div className="row">
                      <div className="col-12 text-center">
                       <h2 className="team_title mb-4">Select Your Document type Below to find out more</h2>
                      </div>  
                   </div>
                <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-12 col-12 mb-4">
                         <div className="c-document-type">
                            <h4>Reports</h4>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                            <div className="btn-box"><i className="fa-solid fa-arrow-right"></i></div>
                         </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-12 col-12 mb-4">
                         <div className="c-document-type">
                            <h4>Articles</h4>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                            <div className="btn-box"><i className="fa-solid fa-arrow-right"></i></div>
                         </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-12 col-12 mb-4">
                         <div className="c-document-type">
                            <h4>Presentations</h4>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                            <div className="btn-box"><i className="fa-solid fa-arrow-right"></i></div>
                         </div>
                    </div>


                    <div className="col-lg-4 col-md-4 col-sm-12 col-12 mb-4">
                         <div className="c-document-type">
                            <h4>Publications</h4>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                            <div className="btn-box"><i className="fa-solid fa-arrow-right"></i></div>
                         </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-12 col-12 mb-4">
                         <div className="c-document-type">
                            <h4>Websites</h4>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                            <div className="btn-box"><i className="fa-solid fa-arrow-right"></i></div>
                         </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-12 col-12 mb-4">
                         <div className="c-document-type">
                            <h4>Business Plans</h4>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                            <div className="btn-box"><i className="fa-solid fa-arrow-right"></i></div>
                         </div>
                    </div>

                </div>
            </div>
         </section>

         <section className="section-padding">
              <div className="container">
              <div className="row justify-content-center">
                      <div className="col-lg-8 col-md-12 col-sm-12 col-12 text-center mb-4">
                       <h2 className="team_title mb-3">Your Dedicated Team Of Expert Editors</h2>
                       <div className="subdescription"><p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p></div>
                      </div>  
                   </div>
                <div className="row">
                    <div className="col-lg-3 col-md-4 col-ms-2 col-12 mb-4">
                          <div className="number-counter">
                                <p>Over</p>
                                <h2>750</h2>
                                <h6>Highly Educated Editors</h6>
                           </div>    
                    </div>

                    <div className="col-lg-3 col-md-4 col-ms-2 col-12 mb-4">
                          <div className="number-counter">
                                 <p>Over</p>
                                <h2>100</h2>
                                <h6>hold PhDs</h6>
                           </div>    
                    </div>

                     

                    <div className="col-lg-3 col-md-4 col-ms-2 col-12 mb-4">
                          <div className="number-counter">
                                <h2>97%</h2>
                                <h6>Hold Graduate Degree</h6>
                           </div>    
                    </div>

                    <div className="col-lg-3 col-md-4 col-ms-2 col-12 mb-4">
                          <div className="number-counter">
                               <p>Qualified in over</p>
                                <h2>200</h2>
                                <h6>subject areas</h6>
                           </div>    
                    </div>

                </div>
              </div>
         </section>


        </>
    );
}

export default BusinessPage;